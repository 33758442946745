import { useEffect, useState } from "react";

import PostRequestCardLoop from "../../components/loops/post-request-card-loop/post-request-card-loop.component";
import PageLoader from "../../components/loaders/page-loader.component";

import { IoCopyOutline } from "react-icons/io5";

import { getPostRequestList } from "../../api/wordpress";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SinglePostRequest from "../single/single-post-request.screen";
import GenericModal from "../../components/modals/generic-modal.component";
import SocialIcons from "../../components/icon-lists/social-icon-list/social-icon-list.component";
import PropertyFilterForm from "../../components/forms/property-filter/property-filter-form.component";
import { isArray } from "../../utils/functions";
import BtnLoader from "../../components/loaders/btn-loader.component";
import copy from "copy-to-clipboard";

const PostRequestsScreen = () => {
  const location = useLocation();
  const [queryParams, setQueryParams] = useState({ per_page: 12, page: 1 });
  const [btnloader, setBtnLoader] = useState(false);
  const [maxPages, setMaxPages] = useState(0);
  const [queryChangeTrigger, setQueryChangeTrigger] = useState("");
  const [postRequestList, setPostRequestList] = useState([]);
  const [postRequest, setPostRequest] = useState({});
  const [postRequestLoader, setPropertyLoader] = useState(true);
  const [shareDialog, setShareDialog] = useState(false);
  const [sharingSlug, setSharingSlug] = useState("");

  const token = JSON.parse(localStorage.getItem("user"))?.token;

  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Post Requests";

    const postRequestListRequest = async () => {
      if (queryChangeTrigger !== "pagination") setPropertyLoader(true);
      const response = await getPostRequestList(token, queryParams);

      let arr = [];
      if (!isArray(response.data)) {
        if (Number.isInteger(Number(Object.keys(response.data)[0]))) {
          arr = Object.keys(response.data).map((key) => {
            return response.data[key];
          });
        } else {
          arr.push(response.data);
        }
      } else {
        arr = response.data;
      }

      if (queryChangeTrigger === "pagination")
        setPostRequestList(postRequestList.concat(arr));
      else setPostRequestList(arr);
      setMaxPages(response.headers["x-wp-totalpages"]);
      setPropertyLoader(false);
      setBtnLoader(false);
    };

    postRequestListRequest().catch((error) => {
      console.log(error);
    });
  }, [queryParams]);

  useEffect(() => {
    if (params.get("action") === "view") {
      let slug = params.get("id");
      if (slug) {
        let post = postRequestList?.find((item) => item.slug === slug);
        if (post) {
          setPostRequest(post);
        }
      }
    } else if (params.get("action") === "chat") {
      let slug = params.get("id");
      if (slug) {
        let post = postRequestList?.find((item) => item.slug === slug);
        if (post) {
          navigate("/dashboard/messages", {
            state: {
              hash: post.author.hash,
            },
          });
        }
      }
    } else if (params.get("action") === "share") {
      let slug = params.get("id");
      if (slug) {
        setSharingSlug(slug);
        setShareDialog(true);
      }
    }
  }, [params, postRequestList]);

  return (
    <>
      <div className="postRequest-list-screen md:pr-8 mb-5 flex flex-col gap-5">
        <PropertyFilterForm
          setQueryParams={setQueryParams}
          setQueryChangeTrigger={setQueryChangeTrigger}
        />

        {postRequestLoader ? (
          <PageLoader
            size="150px"
            className="p-10 w-full flex justify-center"
          />
        ) : Object.keys(postRequest).length !== 0 &&
          params.get("action") === "view" ? (
          <SinglePostRequest
            data={postRequest}
            setSharingSlug={setShareDialog}
          />
        ) : (
          <>
            <PostRequestCardLoop
              className="grid md:grid-cols-2 gap-5"
              theme={2}
              requestList={postRequestList}
            />
            {postRequestList.length > 0 && maxPages > 1 && (
              <div className="flex justify-center w-full mt-5 mb-5">
                {maxPages - 1 >= queryParams["page"] ? (
                  <button
                    className="pagination bg-blue-100 header-font text-primary py-1 px-5 text-sm rounded-full hover:bg-blue-200"
                    onClick={() => {
                      setQueryChangeTrigger("pagination");
                      setQueryParams({
                        ...queryParams,
                        page: queryParams["page"] + 1,
                      });
                      setBtnLoader(true);
                    }}
                  >
                    {btnloader && <BtnLoader color={true} />}
                    Load More
                  </button>
                ) : (
                  <>
                    {btnloader ? (
                      <button className="pagination bg-blue-100 header-font text-primary py-1 px-5 text-sm rounded-full hover:bg-blue-200">
                        {btnloader && <BtnLoader color={true} />}
                        Load More
                      </button>
                    ) : (
                      <button className="pagination bg-blue-100 header-font text-primary py-1 px-5 text-sm rounded-full hover:bg-blue-200">
                        This is the end of properties results
                      </button>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )}
        <GenericModal
          onClick={(event) => {
            if (event.target.classList.contains("close-modal")) {
              setSharingSlug("");
              if (params.get("action") === "share") {
                params.delete("id");
                params.delete("action");
                setParams(params);
              }
              setShareDialog(false);
            }
          }}
          modalState={shareDialog}
          children={
            <>
              <div className="p-[25px] justify-center items-center text-center">
                <button
                  onClick={() => {
                    setSharingSlug("");
                    if (params.get("action") === "share") {
                      params.delete("id");
                      params.delete("action");
                      setParams(params);
                    }
                    setShareDialog(false);
                  }}
                  className={
                    "close-modal py-[3px] px-2 md:py-[5px] md:px-3 text-xs md:text-md rounded-full border border-black absolute top-3 right-3"
                  }
                >
                  X
                </button>
                <div className="flex flex-col gap-5 justify-center text-left">
                  <h2 className="text-xl pt-[25px] font-normal header-font">
                    Share request via
                  </h2>
                  <div className="mb-5">
                    <SocialIcons />
                  </div>
                  <div className="flex items-center justify-between bg-gray-100 rounded-md p-2 w-full border border-stone-300">
                    <p id="share-link" className="text-xs truncate mr-2">
                      {"https://app.ruumies.com" +
                        location.pathname +
                        "?action=view&id=" +
                        sharingSlug}
                    </p>
                    <button
                      onClick={() => {
                        if (
                          copy(
                            "https://app.ruumies.com" +
                              location.pathname +
                              "?action=view&id=" +
                              sharingSlug,
                            {
                              debug: false,
                              message:
                                "https://app.ruumies.com" +
                                location.pathname +
                                "?action=view&id=" +
                                sharingSlug,
                            }
                          )
                        ) {
                          alert("Copied");
                        }
                      }}
                      className="text-secondary flex items-center gap-1 text-sm"
                    >
                      {" "}
                      <IoCopyOutline />
                      Copy
                    </button>
                  </div>
                </div>
              </div>
            </>
          }
        />
      </div>
    </>
  );
};

export default PostRequestsScreen;
