import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GoogleMapReact from "google-map-react";

import { getSingleProperty } from "../../api/wordpress";

import PageLoader from "../../components/loaders/page-loader.component";
import GallerySlider from "../../components/carousels/gallery-slider/gallery-slider.component";
import SocialIcons from "../../components/icon-lists/social-icon-list/social-icon-list.component";
import SimpleIconBox from "../../components/image-boxes/simple-icon-box/simple-icon-box.component";

import {
  TbArmchair,
  TbWifi,
  TbWind,
  TbSwimming,
  TbSquare,
} from "react-icons/tb";
import {
  GiWaterfall,
  GiSittingDog,
  GiHole,
  GiElectricalSocket,
  GiPowerLightning,
} from "react-icons/gi";
import { SiSpringsecurity } from "react-icons/si";
import { IoStarOutline } from "react-icons/io5";
import { ReactComponent as HouseSvg } from "../../assets/vectors/Group-2104.svg";
import { ReactComponent as TagSvg } from "../../assets/vectors/Frame.svg";
import { ReactComponent as MarkerSvg } from "../../assets/vectors/pin-svgrepo-com.svg";
import AlignedImageBox from "../../components/image-boxes/aligned-image-box/aligned-image-box";
import RegularButton from "../../components/buttons/regular-button/regular-button.component";
import ImageSlider from "../../components/carousels/image-slider/image-slider.component";
import ScheduleModal from "../../components/modals/schedule-modal.component";
import { nairaFormart } from "../../utils/functions";

const LocationPin = ({ text }) => (
  <div className="pin">
    <MarkerSvg className="w-[70px]" />
  </div>
);

const SingleProperty = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [property, setProperty] = useState([]);
  const [propertyLoader, setPropertyLoader] = useState(true);
  const [scheduleModalState, setScheduleModalState] = useState(false);
  const token = JSON.parse(localStorage.getItem("user"))?.token;

  useEffect(() => {
    document.title = "Property Listing";

    const propertyListRequest = async () => {
      const response = await getSingleProperty(token, { slug: params.slug });
      setPropertyLoader(false);

      setProperty(response.data);
    };

    propertyListRequest().catch((error) => {
      console.log(error);
    });
  }, [setProperty, params.slug, token]);

  return propertyLoader ? (
    <PageLoader size="150px" className="p-10 w-full flex justify-center" />
  ) : (
    <div className="md:mr-10 mb-[30px] max-w-[100vw] md:w-full overflow-x-hidden ">
      <div
        className="text-[20px] font-regular header-font mb-4 -mt-2 hidden md:block"
        dangerouslySetInnerHTML={{ __html: property.title }}
      ></div>
      {property.acf.gallery && (
        <GallerySlider
          className="mb-7 hidden md:grid"
          gallery={property.acf.gallery}
        />
      )}
      {property.acf.gallery && (
        <div className="md:hidden carousel w-full">
          <ImageSlider
            className="mb-6 w-full  cursor-grab"
            gallery={property.acf.gallery}
            slug={property.slug}
            gap={true}
          />
        </div>
      )}
      <div className="flex flex-col md:w-[80%] mt-3 gap-5 md:gap-10">
        <div className="intro-section flex justify-between w-full">
          <h2
            className="text-[16px] md:text-[20px] font-regular header-font"
            dangerouslySetInnerHTML={{ __html: property.title }}
          ></h2>

          <SocialIcons />
        </div>

        <div className="icons-section flex flex-col gap-5 md:gap-3 font-normal">
          <SimpleIconBox
            text={property.acf.address}
            textClass={"text-black text-[12px] md:text-[16px]"}
          />
          <SimpleIconBox
            icon={<HouseSvg className="h-5 w-5 md:h-[18px]" />}
            text={property.property_type}
            textClass={"text-black text-[12px] md:text-[16px]"}
          />
          <SimpleIconBox
            icon={<TagSvg className="h-6 w-5 md:h-[16px] md:w-[18px]" />}
            text={nairaFormart(property.acf.price)}
            textClass={"text-black text-[12px] md:text-[16px]"}
          />
        </div>

        <div
          className="content-section text-sm text-stone-500 font-light"
          dangerouslySetInnerHTML={{ __html: property.content }}
        />

        <div className="amenities-section border-b border-b-1 border-b-gray-300 md:border-none md:mb-2 -ml-4 px-4 md:px-0 md:ml-0 py-5 md:py-0 w-[100vw] md:w-full mt-3 md:mt-0 bg-gray-50 md:bg-white">
          <h2 className="text-[15px] md:text-[20px] font-normal header-font mb-3">
            Amenities
          </h2>

          <div className="flex text-center gap-10 md:gap-1 w-full overflow-auto md:overflow-none ">
            {property.acf.amenities.map(({ value, label }, index) => {
              let icon;
              let newLabel = label;

              if (value === "other") {
                newLabel = property.acf.other_amenity_field ?? label;
              }

              const svgProps = {
                size: 38,
                className:
                  "bg-sky-100 stroke-secondary p-[8px] rounded-full mb-4",
              };

              switch (value) {
                case "furnished":
                  icon = <TbArmchair {...svgProps} />;
                  break;
                case "wifi":
                  icon = <TbWifi {...svgProps} />;
                  break;
                case "airconditioner":
                  icon = <TbWind {...svgProps} />;
                  break;
                case "water":
                  icon = <GiWaterfall fill="#009FE3" {...svgProps} />;
                  break;
                case "pets":
                  icon = <GiSittingDog fill="#009FE3" {...svgProps} />;
                  break;
                case "borehole":
                  icon = <GiHole fill="#009FE3" {...svgProps} />;
                  break;
                case "swimmingpool":
                  icon = <TbSwimming fill="#009FE3" {...svgProps} />;
                  break;
                case "prepaidmeter":
                  icon = <GiElectricalSocket fill="#009FE3" {...svgProps} />;
                  break;
                case "security":
                  icon = <SiSpringsecurity fill="#009FE3" {...svgProps} />;
                  break;
                case "electricity":
                  icon = <GiPowerLightning fill="#009FE3" {...svgProps} />;
                  break;
                case "other":
                  icon = <TbSquare fill="#009FE3" {...svgProps} />;
                  break;
                default:
                  icon = "";
                // code block
              }

              return (
                <div
                  className="flex flex-col items-center text-xs font-light md:w-[12%]"
                  key={index}
                >
                  {icon}
                  <span className="word-break md:max-w-[80%]">{newLabel}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="md:hidden flex space-x-2">
          <button
            onClick={() => setScheduleModalState(true)}
            type="button"
            className="close-modal w-1/2 inline-flex justify-center items-center rounded-md border shadow-sm md:px-4 py-2 bg-white text-xs md:text-base font-medium text-primary border-primary hover:bg-primary hover:text-white focus:outline-none focus:right-0  sm:ml-3 sm:w-auto sm:text-sm"
          >
            <span> Schedule inspection</span>
          </button>
          <RegularButton
            className={"inline-flex justify-center items-center md:hidden"}
            onClick={() => {
              navigate("/dashboard/messages", {
                state: {
                  hash: property.author.hash,
                },
              });
            }}
            text={
              <span className="py-2 text-xs md:text-sm text-light md:px-3">
                Send Message
              </span>
            }
            linkTo={"#"}
          />
        </div>

        {property.acf.location_on_map && (
          <div className="map-section w-[100vw] md:w-full -ml-4 md:-ml-0">
            <h2 className="text-[15px] md:text-[20px] hidden md:block font-normal header-font mb-4">
              Location On Map
            </h2>

            <div className="google-map h-[160px] md:h-[220px] relative">
              <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
                defaultCenter={property.acf.location_on_map}
                defaultZoom={15}
              >
                <LocationPin
                  lat={property.acf.location_on_map.lat}
                  lng={property.acf.location_on_map.lng}
                  text={property.acf.location_on_map.address}
                />
              </GoogleMapReact>
            </div>
          </div>
        )}

        <div className="author-section flex flex-col gap-5">
          <h2 className="text-[15px] md:text-[20px] font-normal header-font">
            About ruum owner
          </h2>
          <div className="flex justify-between items-center">
            <AlignedImageBox
              check={true}
              profileImage={
                property.author.profile_photo !== null &&
                property.author.profile_photo !== false
                  ? property.author.profile_photo.url
                  : null
              }
              text={property.author.full_name}
              subtext={"0 reviews"}
            />
            <div className="md:flex hidden  space-x-2">
              <button
                onClick={() => setScheduleModalState(true)}
                type="button"
                className="close-modal w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 bg-white text-base font-medium text-primary border-primary hover:bg-primary hover:text-white focus:outline-none focus:right-0  sm:ml-3 sm:w-auto sm:text-sm"
              >
                Schedule inspection
              </button>

              <RegularButton
                className={""}
                onClick={() => {
                  navigate("/dashboard/messages", {
                    state: {
                      hash: property.author.hash,
                    },
                  });
                }}
                text={
                  <span className="py-[3px] text-sm">Message ruum owner</span>
                }
                linkTo={"#"}
              />
            </div>
          </div>
          <div className="content-section text-stone-500 font-light hidden">
            <p>
              Lörem ipsum tager dede cirkulent liksom kavis. Tetrarar kes.
              Hemmadagis fägebel i makrode. Mikaligt galna chipsjukan.
              Hemmadagis fägebel. Lörem ipsum tager dede cirkulent liksom kavis.
              Tetrarar kes. Hemmadagis fägebel i makrode. Mikaligt galna
              chipsjukan.
            </p>
          </div>
          <div className="no-reviews-section md:block md:bg-gray-50 mb-4 py-6 px-3 flex text-xs md:text-sm flex-col justify-center gap-3 md:items-center">
            <div className="flex text-xl md:text-3xl gap-2">
              <IoStarOutline />
              <IoStarOutline />
              <IoStarOutline />
              <IoStarOutline />
              <IoStarOutline />
            </div>
            User has no reviews yet
          </div>
        </div>
      </div>
      <ScheduleModal
        property={property}
        action={() => {
          setScheduleModalState(false);
        }}
        state={scheduleModalState}
      ></ScheduleModal>
    </div>
  );
};

export default SingleProperty;
