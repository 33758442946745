import { Link, Outlet } from "react-router-dom";

import notificationIcon from "../../assets/vectors/notification.svg";
import { ReactComponent as EditSVG } from "../../assets/vectors/edit.svg";
import { ReactComponent as HouseSvg } from "../../assets/vectors/house.svg";
import { ReactComponent as LOGO } from "../../assets/vectors/ruumies-logo.svg";
import logoRepeat from "../../assets/vectors/logo-repeat.svg";

import VerifiedPromptBox from "../../components/text-boxes/verifed-prompt-box/verified-prompt-box.component";
import RegularButton from "../../components/buttons/regular-button/regular-button.component";
import ImageDropdown from "../../components/dropdowns/image-dropdown/image-dropdown.component";
import DashboardMenu from "../../components/menus/dashboard-menu/menu.component";
import ProfileDropdownMenu from "../../components/menus/profile-dropdown-menu/profile-dropdown-menu.component";
import AlignedImageBox from "../../components/image-boxes/aligned-image-box/aligned-image-box";
import VerificationPromptBox from "../../components/text-boxes/verification-prompt-box/verification-prompt-box.component";

import { ReactComponent as EditUserSvg } from "../../assets/vectors/user-edit.svg";
import { ReactComponent as VerifySvg } from "../../assets/vectors/shield-tick.svg";
import { ReactComponent as SwitchRoleSvg } from "../../assets/vectors/Vector-1.svg";
import { ReactComponent as LogoutSvg } from "../../assets/vectors/logout.svg";

import { menuItemList } from "../../utils/defined-constants";

import PreLoader from "../../screens/preloader/preloader.screen";
import { useEffect, useContext, useState } from "react";
import { UserContext } from "../../contexts/user.context";
import DynamicMobileHeader from "../../components/mobile-headers/dynamic-header.component";
import { FormFieldsContext } from "../../contexts/form-fields.context";
import { auth, db, markNotificationAsRead } from "../../utils/firebase";

import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import Moment from "react-moment";

const DashboardLayout = () => {
  const { user, validateUser, logoutUser } = useContext(UserContext);
  const [loader, setLoader] = useState(false);
  const [notifications, setNotifications] = useState(null);
  const { setAllFormFields } = useContext(FormFieldsContext);
  const [pageTitle, setPageTitle] = useState("Rummies");
  const [mainMenuList, setMainMenuList] = useState(menuItemList);

  const fetchNotification = async () => {
    const myUserID = auth.currentUser?.uid;

    if (myUserID != undefined || myUserID != null) {
      const notificationRef = collection(db, "notifications");
      const q = query(
        notificationRef,
        where("userId", "==", myUserID),
        orderBy("createdAt", "desc")
      );

      onSnapshot(q, (querySnapshot) => {
        let notifs = [];
        querySnapshot.forEach((doc) => {
          if (doc.data().unread === true) {
            notifs.push(doc.data());
          }
        });
        notifs = notifs.length > 10 ? notifs.slice(0, 10) : notifs;
        setNotifications(notifs);
      });
    }
  };

  const profilemenuItemList = [
    {
      title: "Edit Profile",
      icon: <EditUserSvg className="inline-block mr-2 h-4" />,
      path: "/my-account",
    },
    {
      title: "Verify Account",
      icon: <VerifySvg className="inline-block -ml-1 mr-1 h-4" />,
      path: "/my-account/?action=verify_account",
    },
    {
      title: "Switch Account",
      icon: <SwitchRoleSvg className="inline-block mr-2 pr-[1px] h-4" />,
      path: "/dashboard",
    },
    {
      title: "Logout",
      icon: <LogoutSvg className="inline-block mr-2 h-4" />,
      action: () => logoutUser("/login"),
    },
  ];

  useEffect(() => {
    const isValidUser = async () => {
      try {
        await setAllFormFields();
        await validateUser();
        setLoader(true);
      } catch (e) {
        logoutUser("/login?acess=failed&reason=access-error");
      }
    };
    try {
      isValidUser();

      if (user != null) {
        if (user.viewing_as === "owner") {
          const ownerMenuList = menuItemList.map((menuItem) => {
            // clone the current object
            const newMenuItem = Object.assign({}, menuItem);
            if (menuItem.path === "/property-list") {
              newMenuItem.path = "/post-requests";
              newMenuItem.title = "Requests";
              newMenuItem.icon = <EditSVG className="inline-block mr-3" />;
            } else if (menuItem.path === "/my-post-requests") {
              newMenuItem.path = "/my-properties";
              newMenuItem.title = "My Properties";
              newMenuItem.icon = <HouseSvg className="inline-block mr-3" />;
            }
            return newMenuItem;
          });
          setMainMenuList(ownerMenuList);
        }
        if (notifications == null) {
          fetchNotification();
        }
      }
    } catch (e) {
      console.log(e);
      logoutUser("/login?acess=failed&reason=access-error");
    }
  }, [user]);

  useEffect(() => {
    var target = document.querySelector("head > title");
    var observer = new window.MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        setPageTitle(mutation.target.textContent);
      });
    });
    observer.observe(target, {
      subtree: true,
      characterData: true,
      childList: true,
    });
  });

  return !loader ? (
    <PreLoader />
  ) : (
    <div className="h-screen w-full flex">
      <div
        className="hidden lg:block min-w-[250px] max-w-[250px] border-r border-r-neutral-100 h-full lg:bg-primary"
        style={{ backgroundImage: `url(${logoRepeat})` }}
      >
        <div className="w-full text-center ">
          <Link to="/">
            <LOGO className="h-24 object-contain w-28 inline-block align-middle" />
          </Link>
        </div>
        <div className="mt-7 ml-10">
          <DashboardMenu menuItemList={mainMenuList} />
        </div>
        {user.verify_user === 0 || !user.verify_user ? (
          <VerificationPromptBox />
        ) : (
          <></>
        )}
        {/* <VerifiedPromptBox /> */}
      </div>
      <div className="w-full overflow-auto" id="dashboard-content">
        <DynamicMobileHeader
          user={user}
          pageTitle={pageTitle}
          logoutUser={logoutUser}
          mainMenuList={mainMenuList}
        />
        <div className="hidden lg:flex sticky top-0 z-[999] bg-white w-full md:min-h-[98px] justify-between items-center px-5 py-3 md:py-5 md:pl-8 md:pr-14 border-b-2 border-b-neutral-100">
          <div className="header-font font-semibold ml-14 md:ml-0 text-[18px] md:text-[20px]">
            {pageTitle}
          </div>
          <div className="flex gap-5 items-center">
            <div className="hidden md:inline-block ">
              {user.viewing_as === "tenant" ? (
                <RegularButton
                  exticon={<EditSVG className="pr-2 svg-icon-white" />}
                  linkTo="my-post-requests?action=add-new"
                />
              ) : (
                <RegularButton
                  exticon={<EditSVG className="pr-2 svg-icon-white" />}
                  linkTo="my-properties?action=add-new"
                  text="Upload property"
                />
              )}
            </div>
            <ImageDropdown
              notifyCount={
                notifications != null
                  ? notifications.filter((e) => e.unread === true).length
                  : 0
              }
              panelElement={
                <div className="w-full flex flex-col divide-y divide-gray-400 divide-opacity-30">
                  {notifications != null && notifications.length > 0 ? (
                    <>
                      {notifications.map((not, index) => (
                        <div key={index} className="flex flex-col py-1">
                          <p className="text-sm">
                            {not.message}
                            <span className="ml-2 text-[10px] capitalize">
                              <Moment fromNow>{not.createdAt.toDate()}</Moment>
                            </span>
                          </p>
                        </div>
                      ))}
                      <button
                        onClick={() =>
                          markNotificationAsRead(auth.currentUser.uid)
                        }
                      >
                        Mark all as read
                      </button>
                    </>
                  ) : (
                    <div>
                      <h4 className="text-center text-sm">
                        No notifications found
                      </h4>
                    </div>
                  )}
                </div>
              }
              image={{ src: notificationIcon, size: "24" }}
              nocaretIcon={true}
            />
            <ImageDropdown
              panelElement={
                <>
                  <AlignedImageBox
                    profileImage={user.profile_photo}
                    text={user.first_name + " " + user.last_name}
                    subtext={"@" + user.nickname}
                  />
                  <ProfileDropdownMenu menuItemList={profilemenuItemList} />
                </>
              }
              check={user.verify_user === 0 || !user.verify_user ? false : true}
              image={{ src: user.profile_photo ? user.profile_photo : "" }}
              className="rounded-full w-[45px] h-[45px]"
            />
          </div>
        </div>
        <div className="p-4 max-w-full ">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
