import { ReactComponent as DefaultSvg } from '../../../assets/vectors/place_black_24dp-1.svg';

const SimpleIconBox = ({ icon, text, textClass }) => {
    return(
        <div className="flex gap-2 items-center text-[10px] md:text-[12px]">
            {icon ? icon : <DefaultSvg className="h-5 w-5 md:h-4 md:w-4" />}
            <span className={ textClass ? textClass : 'text-secondary font-light' }>{ text ? text : 'NG-LG, Ikeja, NG' }</span>
        </div>
    );
}

export default SimpleIconBox;