import React from "react";
import { Link } from "react-router-dom";
import GoogleButtonSignIn from "../../buttons/google-buttons/sign-in.component";
import RegisterForm from "../../forms/register/register-form.component";

export default function AccountRegister({ onCompleted }) {
  return (
    <>
      <h2 className="text-3xl mt-10 mb-8 header-font font-semibold text-center">
        Create Account
      </h2>
      <GoogleButtonSignIn isRegister={true} onCompleted={onCompleted}/>
      <div className="relative flex py-5 items-center">
        <div className="flex-grow border-t border-black border-opacity-20"></div>
        <span className="flex-shrink mx-4 font-light text-sm text-[#656565]">
          Or Sign in with Email
        </span>
        <div className="flex-grow border-t border-black border-opacity-20"></div>
      </div>
      <RegisterForm onCompleted={onCompleted} />
      <div className="mt-5 mb-10 text-center">
        <p className="text-md text-[#656565] font-normal">
          Already have an account?
          <Link className="text-secondary" to="/login">
            {" "}
            login here
          </Link>
        </p>
      </div>
    </>
  );
}
