import { ReactComponent as VerifySVG } from "../../../assets/vectors/verify.svg";
import { ReactComponent as ErrorSVG } from "../../../assets/vectors/error.svg";
import { ReactComponent as LinearSVG } from "../../../assets/vectors/linear.svg";

import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { deletePostRequest, getPropertyList } from "../../../api/wordpress";

import PageLoader from "../../../components/loaders/page-loader.component";
import GenericModal from "../../../components/modals/generic-modal.component";
import NoRequestsScreen from "./no-requests.screen";
import PublishPropertyForm from "../../../components/forms/publish-property/publish-property-form.component";
import MyPropertyCardLoop from "../../../components/loops/property-card-loop/my-property-card-loop.component";

import { isArray } from "../../../utils/functions";
import { IoCopyOutline } from "react-icons/io5";
import SocialIcons from "../../../components/icon-lists/social-icon-list/social-icon-list.component";
import BgModal from "../../../components/modals/background-modal.component";
import copy from "copy-to-clipboard";

const PropertyListScreen = () => {
  const [propertyList, setPropertyList] = useState([]);
  const [countPostObj, setCountPostObj] = useState({
    all: 0,
    occupied: 0,
    unOccupied: 0,
  });
  const [loader, setLoader] = useState(true);
  const [dialogLoader, setDialogLoader] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [shareDialog, setShareDialog] = useState(false);
  const [copiedAlert, setCopiedAlert] = useState(false);
  const [workingSlug, setWorkingSlug] = useState("");
  const [callback, setCallback] = useState("");
  const [reloadPosts, setReloadPosts] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const token = JSON.parse(localStorage.getItem("user"))?.token;
  const email = JSON.parse(localStorage.getItem("user"))?.user?.user_email;

  const [params, setParams] = useSearchParams();
  const location = useLocation();

  const closeAllDialog = () => {
    setConfirmDialog(false);
    setDialogLoader(false);
    setErrorDialog(false);
    setShareDialog(false);
    setSuccessDialog(false);
    setCopiedAlert(false);
    setDialogData(null);
  };

  const handleDelete = async () => {
    setDialogLoader(true);
    try {
      await deletePostRequest(token, workingSlug);
      closeAllDialog();
      setDialogData({
        title: "Success",
        subtitle: "Your Post has been successfully deleted!",
        svgComp: <VerifySVG />,
      });
      setSuccessDialog(true);
    } catch (error) {
      closeAllDialog();
      setDialogData({
        title: "An Error Occured!",
        subtitle: "There was an unknown error in your , please try again.",
        svgComp: <ErrorSVG className="h-[70px]" />,
      });
      setErrorDialog(true);
    }
  };

  useEffect(() => {
    if (callback === "handleDelete") {
      handleDelete();
    }
    setCallback("");
  }, [callback]);

  useEffect(() => {
    if (params.get("action") !== "delete" && params.get("action") !== "share") {
      setLoader(true);
      const propertyListRequest = async () => {
        let arr = [];
        const response = await getPropertyList(token, { author: email });
        if (!isArray(response.data)) {
          if (Number.isInteger(Number(Object.keys(response.data)[0]))) {
            arr = Object.keys(response.data).map((key) => {
              return response.data[key];
            });
          } else {
            arr[0] = response.data;
          }
        } else {
          arr = response.data;
        }
        setPropertyList(arr);
        setCountPostObj({
          ...countPostObj,
          all: response.headers["x-wp-total"],
        });
        setLoader(false);
        setReloadPosts(false);
      };

      propertyListRequest().catch((error) => {
        console.log(error);
        setLoader(false);
      });
    }
  }, [reloadPosts]);

  useEffect(() => {
    if (params.get("action") === "delete") {
      setDialogData({
        title: "Are you sure you want to delete this property  ?",
        subtitle:
          "Note that this action is irreversible. So if you sure about this, click confirm to proceed with delete",
        svgComp: "",
      });
      setConfirmDialog(true);
    } else if (params.get("action") === "edit") {
      setLoader(false);
    } else if (params.get("action") === "share") {
      let slug = params.get("id");
      if (slug) {
        setWorkingSlug(slug);
        setShareDialog(true);
      }
    } else if (params.get("action") === "copy") {
      let slug = params.get("id");
      if (slug) {
        setDialogData({
          title: "Payment Link Copied!",
          subtitle:
            "This payment link is now copied to your clipboard, you can paste your payment link to ruumies on your chat.",
          svgComp: <VerifySVG />,
        });
        setCopiedAlert(true);
        navigator.clipboard.writeText(
          "https://app.ruumies.com/checkout?paymentID=" + slug
        );
      }
    }
  }, [params]);

  return (
    <>
      {loader ? (
        <PageLoader size="150px" className="p-10 w-full flex justify-center" />
      ) : propertyList.length === 0 && params.get("action") !== "edit" ? (
        <NoRequestsScreen />
      ) : params.get("action") === "edit" ? (
        <PublishPropertyForm
          setReloadPosts={setReloadPosts}
          setParams={setParams}
        />
      ) : (
        <div className="max-w-full overflow-x-hidden">
          <div className="grid grid-cols-3 gap-3 md:gap-5">
            <button className="border border-gray-300 hover:border-secondary rounded-md flex flex-col md:flex-row gap-3 md:gap-6 items-center py-3 md:p-8">
              <span className="h-6 w-6 md:h-12 md:w-12 rounded-md bg-[#959595] bg-opacity-10 flex items-center justify-center pp-card-icon">
                <LinearSVG />
              </span>
              <div className="flex flex-row gap-3 items-center md:items-start text-xl md:text-3xl font-semibold md:font-bold">
                {countPostObj.all}
                <span className="text-sm text-gray-500 md:text-[20px] font-light text-left">
                  Uploaded
                  <br />
                  Property
                </span>
              </div>
            </button>
            <button className="bg-primary hover:border-primary rounded-md flex flex-col md:flex-row gap-3 md:gap-6 items-center py-3 md:p-8 shadow-sm shadow-secondary">
              <span className="h-6 w-6 md:h-12 md:w-12 rounded-md bg-[#009FE3] bg-opacity-20 flex items-center justify-center">
                <LinearSVG />
              </span>
              <div className="flex flex-row gap-3 items-center md:items-start text-xl md:text-3xl font-semibold md:font-bold text-white">
                {countPostObj.occupied}
                <span className="text-sm text-white md:text-[20px] font-light text-left">
                  Occupied
                  <br />
                  Property
                </span>
              </div>
            </button>
            <button className="border border-gray-300 hover:border-secondary rounded-md flex flex-col md:flex-row gap-3 md:gap-6 items-center py-3 md:p-8">
              <span className="h-6 w-6 md:h-12 md:w-12 rounded-md bg-[#959595] bg-opacity-10 flex items-center justify-center pp-card-icon">
                <LinearSVG />
              </span>
              <div className="flex flex-row gap-3 items-center md:items-start text-xl md:text-3xl font-semibold md:font-bold">
                {countPostObj.all}
                <span className="text-sm text-gray-500 md:text-[20px] font-light text-left">
                  Unoccupied
                  <br />
                  Property
                </span>
              </div>
            </button>
          </div>
          <MyPropertyCardLoop propertyList={propertyList} />
        </div>
      )}
      <GenericModal
        onClick={(event) => {
          if (event.target.classList.contains("close-modal")) {
            let id = params.get("id");
            setWorkingSlug(id);

            params.delete("id");
            params.delete("action");
            params.delete("share");

            setParams(params);
            closeAllDialog();
          }
          if (event.target.classList.contains("handle-delete")) {
            setCallback("handleDelete");
          }
          if (event.target.classList.contains("reload-window")) {
            setReloadPosts(true);
          }
        }}
        modalState={
          confirmDialog || successDialog || errorDialog || copiedAlert
        }
        children={
          <>
            {dialogLoader ? (
              <>
                <PageLoader />
              </>
            ) : (
              <div className="p-[54px] justify-center items-center text-center">
                <button
                  onClick={(event) => {
                    let id = params.get("id");
                    setWorkingSlug(id);

                    params.delete("id");
                    params.delete("action");
                    params.delete("share");

                    setParams(params);
                    closeAllDialog();

                    if (event.target.classList.contains("handle-delete")) {
                      setCallback("handleDelete");
                    }
                    if (event.target.classList.contains("reload-window")) {
                      setReloadPosts(true);
                    }
                  }}
                  className={
                    (successDialog && "reload-window") +
                    " close-modal py-[5px] px-3 rounded-full border border-black absolute top-3 right-3"
                  }
                >
                  X
                </button>
                <div className="flex flex-col gap-5 justify-center items-center">
                  {dialogData?.svgComp}
                  {dialogData?.title && (
                    <h3 className="text-bold text-[20px] header-font">
                      {dialogData?.title}
                    </h3>
                  )}
                  {dialogData?.subtitle && (
                    <p className="font-light text-strong-[200] text-md mb-4">
                      {dialogData?.subtitle}
                    </p>
                  )}
                </div>
                {confirmDialog && (
                  <div className="justify-center px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="close-modal w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="close-modal handle-delete mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Confirm
                    </button>
                  </div>
                )}
              </div>
            )}
          </>
        }
      />
      <BgModal modalState={dialogLoader} />
      <GenericModal
        onClick={(event) => {
          if (event.target.classList.contains("close-modal")) {
            setWorkingSlug("");
            if (params.get("action") === "share") {
              let id = params.get("id");
              setWorkingSlug(id);

              params.delete("id");
              params.delete("action");
              params.delete("share");

              setParams(params);
            }
            closeAllDialog();
          }
        }}
        modalState={shareDialog}
        children={
          <>
            <div className="p-[25px] justify-center items-center text-center">
              <button
                onClick={() => {
                  setWorkingSlug("");
                  if (params.get("action") === "share") {
                    let id = params.get("id");
                    setWorkingSlug(id);

                    params.delete("id");
                    params.delete("action");
                    params.delete("share");

                    setParams(params);
                  }
                  closeAllDialog();
                }}
                className={
                  "close-modal py-[5px] px-3 rounded-full border border-black absolute top-3 right-3"
                }
              >
                X
              </button>
              <div className="flex flex-col gap-5 justify-center text-left">
                <h2 className="text-xl pt-[25px] font-normal header-font">
                  Share via
                </h2>
                <div className="mb-5">
                  <SocialIcons />
                </div>
                <div className="flex items-center justify-between bg-gray-100 rounded-md p-2 w-full border border-stone-300">
                  <p id="share-link" className="text-xs truncate mr-2">
                    {"https://app.ruumies.com" +
                      location.pathname +
                      "?action=view&id=" +
                      workingSlug}
                  </p>
                  <button
                    onClick={() => {
                      if (
                        copy(
                          "https://app.ruumies.com" +
                            location.pathname +
                            "?action=view&id=" +
                            workingSlug,
                          {
                            debug: false,
                            message:
                              "https://app.ruumies.com" +
                              location.pathname +
                              "?action=view&id=" +
                              workingSlug,
                          }
                        )
                      ) {
                        alert("Copied");
                      }
                    }}
                    className="text-secondary flex items-center gap-1 text-sm"
                  >
                    {" "}
                    <IoCopyOutline />
                    Copy
                  </button>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default PropertyListScreen;
