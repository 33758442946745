import { Link } from "react-router-dom";
import { ReactComponent as EditSVG } from '../../../assets/vectors/edit.svg'

const NoPaymentHistoryScreen = () => {
    return(
        <>
            <div className='flex flex-col justify-center items-center h-[80vh] gap-4'>
                <EditSVG className="h-[200px] w-[200px]" />
                <h4 className="font-semibold text-lg text-center header-font" >No Payments yet!</h4>
                <p className="text-center text-sm text-strong-100 font-light">No payments made here, start a transaction <br/> and find your payment history here.</p>
                <Link to="../my-account/?action=verify_account">
                </Link>
            </div>
        </>
    );
}

export default NoPaymentHistoryScreen;