import { Link } from "react-router-dom";
import { useEffect } from "react";

import GoogleButtonSignIn from "../../components/buttons/google-buttons/sign-in.component";
import LoginForm from "../../components/forms/login/login-form.component";
import logo from "../../assets/images/logoimg.png";
import image from "../../assets/images/login-bg.jpg";
import RuumiesLogo from "../../assets/images/ruumies-white-logo.svg";

const LoginScreen = () => {
  useEffect(() => {
    document.title = "Login - Rummies";
  });

  return (
    <>
      <div
        className="hidden md:inline-block md:w-1/3 h-full bg-cover bg-top"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="h-full flex flex-col justify-between px-6 py-10">
          <img src={RuumiesLogo} alt="Ruumies Logo" width={110} height={23} />
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div className="flex flex-col">
            <h4 className="text-white text-[42px] font-medium">
              Get the right ruummate in few clicks
            </h4>
            <p className="text-white text-[18px] text-opacity-80 font-bold">
              Simplified for easy access to a teeming backlog of users.
            </p>
          </div>
          {/* <div></div>
          <div></div> */}
        </div>
      </div>
      <div className="inline-block w-full md:w-2/3 h-full pt-10 overflow-auto">
        <div className="p-5 pt-0 lg:w-[500px] md:w-2/3 mx-auto">
          <div className="w-full flex flex-col justify-center">
            <div className="mb-8 flex items-center justify-center">
              <img src={logo} alt="logo" width="110" height="23" />
            </div>

            <h2 className="text-3xl mt-10 mb-8 header-font font-semibold text-center">
              Login
            </h2>
            <GoogleButtonSignIn />
            <div className="relative flex py-5 items-center">
              <div className="flex-grow border-t border-black border-opacity-20"></div>
              <span className="flex-shrink mx-4 font-light text-sm text-[#656565]">
                Or
              </span>
              <div className="flex-grow border-t border-black border-opacity-20"></div>
            </div>
            <LoginForm />
            <div className="mt-5 mb-10 text-center">
              <p className="mt-5 mb-10 text-md text-[#656565]">
                Don’t have an account?
                <Link className="text-secondary" to="/register">
                  {" "}
                  Create one here
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginScreen;
