import PublishPostRequestForm from "../../../components/forms/publish-post-request/publish-post-request-form.component";

const PublishRequestScreen = () => {
    return(
        <div className="mt-2 mb-5">
            <PublishPostRequestForm />
        </div>
    );
}

export default PublishRequestScreen;