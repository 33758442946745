import React from "react";
import ScheduleCard from "./schedule-card";

export default function ScheduleList({ data, setLoader, requestHistory }) {
  return (
    <div className="w-full divide-y divider-black">
      <div className="table-header flex font-semibold header-font text-[14px] gap-8 pb-2">
        <div className="w-[30%]">Property</div>
        <div className="w-[15%]">Date</div>
        <div className="w-[15%]">Time</div>
        <div className="w-[20%] text-center">Status</div>
        <div className="w-[20%]"></div>
      </div>
      {data.length > 0 &&
        data.map((v, index) => (
          <ScheduleCard
            data={v}
            key={index}
            setLoader={setLoader}
            requestHistory={() => requestHistory()}
          />
        ))}
    </div>
  );
}
