import PostRequestCard from "../../components/card-items/post-request-card-item/post-request-card-item.component";

const SinglePostRequest = ({data, setSharingSlug}) => {
    return (
        <div className="md:w-[60%]">
            <PostRequestCard lightmode={true} data={data} setSharingDialog={setSharingSlug} />
        </div>
    );
}

export default SinglePostRequest;