import PasswordUpdateForm from "../../../components/forms/password-update/password-update-form.component";

const PasswordSecurityScreen = () => {
    return(
        <>
            <div className="mb-6">
                <h4 className=" header-font">Password & Security</h4>
            </div>
            <PasswordUpdateForm />
        </>
    );
}

export default PasswordSecurityScreen;