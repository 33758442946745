import React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../contexts/user.context";

export default function CompletedRegister() {
  const navigator = useNavigate();

  const { user } = useContext(UserContext);

  let destination;

  if (user != null) {
    if (user.viewing_as == "owner") {
      destination = "/dashboard/post-requests";
    } else if (user.viewing_as == "tenant") {
      destination = "/property-list";
    } else {
      destination = "/dashboard";
    }
  } else {
    destination = "/dashboard";
  }

  return (
    <>
      <div className="mt-12 flex justify-center">
        <div className="bg-primary h-[64px] w-[64px] py-3 rounded-full text-center text-white flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-8 h-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12.75l6 6 9-13.5"
            />
          </svg>
        </div>
      </div>
      <h2 className="text-3xl mt-10 mb-4 header-font font-semibold text-center">
        Congratulations
      </h2>
      <p className="mb-8 text-center text-sm">
        You have completed onboarding, you can now start using Rummies
      </p>
      <div className="field_wrapper mb-5">
        <button
          type="button"
          onClick={() => navigator(destination)}
          className="w-full rounded-md text-white p-3 bg-primary hover:bg-blue-900 font-semibold cursor-pointer"
        >
          View Your Dashboard
        </button>
      </div>
    </>
  );
}
