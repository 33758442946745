import { Gallery, Item } from 'react-photoswipe-gallery';
import 'photoswipe/dist/photoswipe.css';

const GallerySlider = ({gallery, ...params}) => {
    const smallItemStyles = {
        borderRadius: '10px',
        cursor: 'pointer',
        objectFit: 'cover',
        width: '100%',
        height: '140px',
    }
    return (
      <Gallery>
        <div
          className={'grid grid-cols-5 grid-rows-2 gap-5 h-[300px] ' +  {...params}['className']}
        >
        {
            gallery.map((image, index) => {
                if(index === 0){
                    return (
                        <Item
                            original={image.full_image_url}
                            thumbnail={image.full_image_url}
                            width="1911"
                            height="936"
                            alt={image.title}
                            key={image.id}
                        >
                            {({ ref, open }) => (
                            <img
                                style={{ borderRadius: '10px', cursor: 'pointer'}}
                                className='col-span-3 row-span-2 h-[300px] object-cover w-full'
                                src={image.full_image_url}
                                ref={ref}
                                onClick={open}
                                alt=''
                            />
                            )}
                        </Item>
                    );
                } else if (index > 4){
                    return(
                        <Item
                            original={image.full_image_url}
                            thumbnail={image.full_image_url}
                            width="1911"
                            height="936"
                            alt={image.title}
                            key={image.id}
                        >
                            {({ ref, open }) => (
                                <img
                                    style={smallItemStyles}
                                    className='hidden' 
                                    src={image.full_image_url}
                                    ref={ref}
                                    onClick={open}
                                    alt={image.title}
                                />
                            )}
                        </Item>
                    )
                } else{
                    return(
                        <Item
                            original={image.full_image_url}
                            thumbnail={image.full_image_url}
                            width="1911"
                            height="936"
                            alt={image.title}
                            key={image.id}
                        >
                            {({ ref, open }) => (
                                <img
                                    style={smallItemStyles}
                                    src={image.full_image_url}
                                    ref={ref}
                                    onClick={open}
                                    alt={image.title}
                                />
                            )}
                        </Item>
                    )
                }
            })
        }
         
        </div>
      </Gallery>
    )
}

export default GallerySlider;