import React from "react";

const Step = ({ numberOfStep, currentStep }) => {
  return (
    <div className="flex items-center">
      {Array(numberOfStep ?? 1)
        .fill()
        .map((i, idx) => (
          <>
            <div
              key={i}
              className={`flex items-center ${
                currentStep == idx + 1 ? `text-white` : `text-black`
              } relative`}
            >
              <div
                className={`${
                  currentStep == idx + 1 ? `bg-primary` : ``
                } rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2  ${
                  idx + 1 > currentStep ? `border-secondary` : `border-primary`
                } text-center`}
              >
                <h4>{idx + 1}</h4>
              </div>
            </div>
            {idx + 1 < numberOfStep && (
              <div
                className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
                  idx + 1 > currentStep ? `border-secondary` : `border-primary`
                } `}
              ></div>
            )}
          </>
        ))}

      {/* <div className="flex items-center text-white relative">
        <div className="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 bg-primary border-primary text-center">
          <h4>2</h4>
        </div>
      </div>
      <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-secondary"></div>
      <div className="flex items-center text-gray-500 relative">
        <div className="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-secondary text-center">
          <h4>3</h4>
        </div>
      </div>
      <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-secondary"></div>
      <div className="flex items-center text-gray-500 relative">
        <div className="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-secondary text-center">
          <h4>4</h4>
        </div>
      </div> */}
    </div>
  );
};

export default Step;
