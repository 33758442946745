import { useContext, useState } from "react";
import { FormFieldsContext } from "../../../contexts/form-fields.context";
import { ReactComponent as HouseSvg } from "../../../assets/vectors/Group-2104.svg";
import { ReactComponent as TagSvg } from "../../../assets/vectors/Frame.svg";
import { ReactComponent as MarkSvg } from "../../../assets/vectors/place_black_24dp-1.svg";

import { ReactComponent as SearchSvg } from "../../../assets/vectors/icons8-search.svg";
import ListBoxDropdown from "../../fields/listbox-dropdown-field/listbox-dropdown.component";
import { useEffect } from "react";
import SearchLocationInput from "../../fields/google-maps-search-input/google-maps-search-input.component";
import { useLocation } from "react-router-dom";

const PropertyFilterForm = ({ setQueryParams, setQueryChangeTrigger }) => {
  const { propertyTypes, budgetRanges } = useContext(FormFieldsContext);
  const [addressObj, setAddressObj] = useState();
  const [minBudgets] = useState([
    {
      name: "Minimum Budget",
      value: "",
    },
    ...budgetRanges,
  ]);
  const [maxBudgets] = useState([
    {
      name: "Maximum Budget",
      value: "",
    },
    ...budgetRanges,
  ]);
  const [filters, setFilters] = useState({});
  const location = useLocation();

  const constructFilterParams = (data) => {
    let i = 0;
    let filterObj = { filter: {} };
    if (
      (data.address_components === undefined ||
        data.address_components.length === 0) &&
      data.maximum_budget === undefined &&
      data.s === undefined &&
      data.minimum_budget === undefined &&
      data.property_type === undefined
    ) {
      return false;
    }

    if (data.s) filterObj["filter"]["s"] = data.s;
    if (
      data.property_type ||
      data.address_components ||
      data.minimum_budget ||
      data.maximum_budget
    ) {
      filterObj["filter"]["meta_query"] = {};
      if (data.property_type) {
        filterObj["property_type"] = data.property_type;
      }

      if (data.address_components && data.address_components.length > 0) {
        filterObj["filter"]["meta_query"][i] = {};
        let core_address_components = data.address_components.filter(
          (v, i, a) => a.indexOf(v) === i
        );
        core_address_components.forEach((component, index) => {
          filterObj["filter"]["meta_query"][i][index] = {
            key: "address_components",
            value: component,
            compare: "LIKE",
          };
        });
        filterObj["filter"]["meta_query"][i]["relation"] = "AND";
        i++;
      }

      if (data.minimum_budget || data.maximum_budget) {
        filterObj["filter"]["meta_query"][i] = {
          key:
            location.pathname === "/dashboard/post-requests"
              ? "budget"
              : "price",
          value: [data.minimum_budget || 0, data.maximum_budget],
          compare: "BETWEEN",
        };
      }

      if (i > 1) filterObj["filter"]["meta_query"]["relation"] = "AND";
    }

    return filterObj;
  };

  useEffect(() => {
    let components = {};
    if (addressObj) {
      let address_components = addressObj.address_components;
      address_components.forEach((address_component) => {
        address_component["types"].forEach((type) => {
          components[type] = address_component.long_name;
        });
      });
    }
    let addressComponentBuildObjects = { address_components: [] };

    if (components.neighbourhood) {
      addressComponentBuildObjects["address_components"].push(
        components.neighbourhood
      );
    } else if (components.sublocality_level_1) {
      addressComponentBuildObjects["address_components"].push(
        components.sublocality_level_1
      );
    } else if (components.locality) {
      addressComponentBuildObjects["address_components"].push(
        components.locality
      );
    }

    if (components.administrative_area_level_2) {
      addressComponentBuildObjects["address_components"].push(
        components.administrative_area_level_2
      );
    }
    if (components.administrative_area_level_1) {
      addressComponentBuildObjects["address_components"].push(
        components.administrative_area_level_1
      );
    }
    setFilters({ ...filters, ...addressComponentBuildObjects });
  }, [addressObj]);

  useEffect(() => {
    let data = constructFilterParams(filters);
    setQueryChangeTrigger("");
    if (data) setQueryParams({ ...{ per_page: 12, page: 1 }, ...data });
  }, [filters, setQueryParams, setQueryChangeTrigger]);

  return (
    <div>
      <form id="filter_form " className="w-[80%]">
        <div className="flex gap-2 mt-1 text-gray-400 border h-[52px] p-2 border-stone-200 bg-stone-100 rounded-md w-full ">
          <MarkSvg className="h-5 w-5 mt-1" aria-hidden="true" />
          <SearchLocationInput
            setAddressObj={setAddressObj}
            placeholder="LGA/City"
            required
            localeTypes={["political"]}
            parentClassName="mb-0 -mt-[12px] w-full bg-transparent h-[52px]"
            className=" w-full px-1 focus:outline-none text-[12px]  -mb-[10px] bg-transparent h-full"
          />
        </div>
        {/* <div className='border border-stone-200 rounded-md flex h-[52px] items-center p-4 gap-4 bg-stone-100 focus:border-secondary'>
                    <SearchSvg className='w-[20px] h-[20px]' />
                    <input className='w-full focus:outline-none text-stone-700 font-light header-font bg-stone-100' onChange={(e) =>  setFilters({...filters, 's' : e.target.value}) } placeholder='Search for ruums...' />
                </div> */}
        <div className="hidden md:grid grid-cols-5 mt-3 mb-2 gap-4">
          {/* <div className='flex gap-2 mt-1 text-gray-400 border h-[44px] p-2 border-stone-200 rounded-md w-full '>
                        <MarkSvg className="h-5 w-5 mt-1" aria-hidden="true"  />
                        <SearchLocationInput
                            setAddressObj={setAddressObj}
                            placeholder="LGA/City"
                            required
                            localeTypes={["political"]}
                            parentClassName="mb-0 -mt-[10px]"
                            className=' w-full px-1 focus:outline-none text-[12px]  -mb-10'
                        />
                    </div> */}
          <ListBoxDropdown
            icon={
              <HouseSvg className="h-5 w-5 text-gray-400" aria-hidden="true" />
            }
            dataKey="property_type"
            lists={[{ name: "Property Type", value: "" }, ...propertyTypes]}
            getSelected={setFilters}
            filters={filters}
          />
          <ListBoxDropdown
            icon={
              <TagSvg className="h-5 w-5 text-gray-400" aria-hidden="true" />
            }
            dataKey="minimum_budget"
            lists={minBudgets}
            getSelected={setFilters}
            filters={filters}
          />
          <ListBoxDropdown
            icon={
              <TagSvg className="h-5 w-5 text-gray-400" aria-hidden="true" />
            }
            dataKey="maximum_budget"
            lists={maxBudgets}
            getSelected={setFilters}
            filters={filters}
          />
        </div>
      </form>
    </div>
  );
};

export default PropertyFilterForm;
