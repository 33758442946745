const MessageForm = ({
  handleSubmit,
  text,
  setText,
  setImg,
  img,
  isSending,
}) => {
  return (
    <>
      <form
        className="relative bottom-0 flex items-end justify-between w-full p-3 border-t border-neutral-200"
        onSubmit={handleSubmit}
      >
        <button
          type="button"
          className="cursor-pointer disabled:cursor-not-allowed"
          disabled={isSending}
        >
          <label htmlFor="img" className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
              />
            </svg>
            <input
              onChange={(e) => setImg(e.target.files[0])}
              type="file"
              id="img"
              accept="image/*"
              style={{ display: "none" }}
            />
          </label>
        </button>

        <div className="flex flex-col w-full mx-3">
          {img !== null && img !== "" && (
            <div className="relative flex flex-row mb-1">
              <button
                onClick={() => setImg("")}
                className="absolute bg-secondary flex items-center justify-center text-white rounded-full w-4 h-4 text-sm"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-3 h-3"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <img
                src={URL.createObjectURL(img)}
                className="w-[50px] h-[50px] border rounded-md"
                alt="attachement"
              />
            </div>
          )}

          <input
            type="text"
            placeholder="Message"
            value={text}
            className="block w-full py-2 pl-4 border border-gray-300 rounded-full outline-none font-light focus:text-gray-700"
            onChange={(e) => setText(e.target.value)}
          />
        </div>
        <button
          disabled={isSending}
          className="bg-primary flex gap-2 py-2 px-4 text-white border rounded-lg items-center disabled:cursor-not-allowed"
          type="submit"
        >
          Send
          <svg
            className="w-5 h-5 text-white origin-center transform rotate-90"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
          </svg>
        </button>
      </form>
    </>
  );
};

export default MessageForm;
