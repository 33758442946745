import image from "../../../assets/images/user.png";
import { ReactComponent as CheckedSVG } from "../../../assets/vectors/Groupchecked.svg";

const AlignedImageBox2 = ({ profileImage, text, subtext, check }) => {
  return (
    <div className="flex items-center mb-1">
      <img
        className="w-[37px] h-[37px] md:h-[50px] md:w-[50px] rounded-md"
        src={profileImage ? profileImage : image}
        alt=""
        height="50"
        width="50"
      />
      <div className="flex flex-col ml-3 gap-1">
        <div className="flex gap-4">
          <span
            className="md:font-normal text-[15px] md:text-md"
            dangerouslySetInnerHTML={{ __html: text }}
          ></span>
          {check && <CheckedSVG className="w-4 h-4 mt-[3px] md:w-5 md:h-5" />}
        </div>
        <p className="text-xs md:text-sm lowercase text-stone-400 font-light mt-2 md:mt-0">
          {subtext ? subtext : "@teemah"}
        </p>
      </div>
    </div>
  );
};
export default AlignedImageBox2;
