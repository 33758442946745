import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateWpUser } from "../../../api/wordpress";
import { UserContext } from "../../../contexts/user.context";
import NewSwitchRoleBox from "../../image-boxes/switch-role-image-box/new-switch-role-box.component";
import ErrorModal from "../../modals/error-modal.component";

export default function RoleRegister({ onCompleted }) {
  const [loader, setLoader] = useState(false);
  const [role, setRole] = useState("owner");
  const [error, setError] = useState(false);
  const navigator = useNavigate();
  const { accessToken, persistUpdatedUser } = useContext(UserContext);

  const handleClick = async () => {
    let destination =
      (role === "owner" && "/dashboard/post-requests") ||
      (role === "tenant" && "property-list") ||
      "";
    const formData = new FormData();
    formData.append("viewing_as", role);
    try {
      setLoader(true);
      const response = await updateWpUser(accessToken, formData);
      persistUpdatedUser(response.data);
      setLoader(false);
      if (onCompleted != null) {
        onCompleted();
      } else {
        navigator(destination);
      }
    } catch (error) {
      setLoader(false);
      const { code, message } = error.response.data
        ? error.response.data
        : error;

      setError({
        code: code,
        message: message,
        status: true,
      });
    }
  };

  return (
    <div className="mt-10">
      <h2 className="text-3xl mt-10 mb-4 header-font font-semibold text-center">
        Select what you need
      </h2>
      <p className="mb-8 text-center text-sm">
        You can easily switch between accounts once you are logged in
      </p>
      <div className="my-6 flex items-center justify-center space-x-6">
        <NewSwitchRoleBox
          onClick={() => setRole("owner")}
          title="I need a Rummate"
          role="owner"
          isActive={role === "owner"}
          description="Click to find the most suitable and budget-friendly apartments in location of choice. "
        >
          <svg
            width="40"
            height="28"
            viewBox="0 0 40 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`${role === "owner" ? `text-white` : `text-primary`}`}
          >
            <path
              d="M36 10V4C36 1.8 34.2 0 32 0H8C5.8 0 4 1.8 4 4V10C1.8 10 0 11.8 0 14V24H2.66L4 28H6L7.34 24H32.68L34 28H36L37.34 24H40V14C40 11.8 38.2 10 36 10ZM18 10H8V4H18V10ZM32 10H22V4H32V10Z"
              fill="currentColor"
            />
          </svg>
        </NewSwitchRoleBox>
        <NewSwitchRoleBox
          onClick={() => setRole("tenant")}
          title="I need a Rum"
          role="tenant"
          isActive={role === "tenant"}
          description="Click to find the most suitable and budget-friendly apartments in location of choice. "
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            className={`${role === "tenant" ? `text-white` : `text-primary`}`}
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1234_11456)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.7832 21.8833C30.0665 23.4333 31.6665 25.5333 31.6665 28.3333V33.3333H38.3332V28.3333C38.3332 24.7 32.3832 22.55 27.7832 21.8833Z"
                fill="currentColor"
              />
              <path
                d="M14.9987 20C18.6806 20 21.6654 17.0152 21.6654 13.3333C21.6654 9.65139 18.6806 6.66663 14.9987 6.66663C11.3168 6.66663 8.33203 9.65139 8.33203 13.3333C8.33203 17.0152 11.3168 20 14.9987 20Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.9999 20C28.6832 20 31.6665 17.0166 31.6665 13.3333C31.6665 9.64996 28.6832 6.66663 24.9999 6.66663C24.2165 6.66663 23.4832 6.83329 22.7832 7.06663C24.1665 8.78329 24.9999 10.9666 24.9999 13.3333C24.9999 15.7 24.1665 17.8833 22.7832 19.6C23.4832 19.8333 24.2165 20 24.9999 20Z"
                fill="currentColor"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.9993 21.6666C10.5493 21.6666 1.66602 23.9 1.66602 28.3333V33.3333H28.3327V28.3333C28.3327 23.9 19.4493 21.6666 14.9993 21.6666Z"
                fill="currentColor"
              />
            </g>
            <defs>
              <clipPath id="clip0_1234_11456">
                <rect width="40" height="40" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </NewSwitchRoleBox>
      </div>
      <div className="field_wrapper mb-5">
        <button
          type="button"
          onClick={handleClick}
          className="w-full flex items-center justify-center rounded-md text-white p-3 bg-primary hover:bg-blue-900 font-semibold cursor-pointer"
        >
          {loader ? (
            <svg
              role="status"
              className="inline w-6 h-6 mr-3 text-white animate-spin border-2 rounded-full border-gray-500"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
          ) : (
            <>
              Next
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                />
              </svg>
            </>
          )}
        </button>
      </div>
      <ErrorModal
        action={(event) => {
          event.target.classList.contains("close-modal") && setError(false);
        }}
        title="An Error Occured!"
        description="There was an unknown error in your user role change, please try
        again."
        state={error}
      />
    </div>
  );
}
