import { useState, createContext, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getWpUser } from "../api/wordpress/index";
import {
  createUserDocumentFromAuth,
  onAuthStateChangedListener,
  signOutUser,
} from "../utils/firebase";
import { deepEqual } from "../utils/functions";

export const UserContext = createContext({});

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user"))?.user || null
  );
  const [fsUser, setFsUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accessToken, setAccessToken] = useState(
    JSON.parse(localStorage.getItem("user"))?.token || null
  );

  const navigate = useNavigate();

  const persistUser = useCallback((data) => {
    setUser(data.user);
    setAccessToken(data.token);
    setIsAuthenticated(!!data?.token);
    localStorage.setItem('user', JSON.stringify(data));
  }, [
    setUser,
    setAccessToken,
    setIsAuthenticated
  ]);

  const persistUpdatedUser = useCallback((data) => {
    const newUser = { ...data };
    setUser(newUser);
    localStorage.setItem('user', JSON.stringify({
      token: JSON.parse(localStorage.getItem('user'))?.token,
      user: newUser
    }));
  }, [setUser]);

  const validateUser = useCallback(async () => {
    if (accessToken) {
      try {
        const response = await getWpUser(accessToken);
        const newuser = response.data;
        if (!deepEqual(newuser, user)) {
          persistUpdatedUser(newuser);
        }
      } catch (error) {
        throw error;
      }
    } else {
      throw new Error("no auth token");
    }
  }, [accessToken, user, persistUpdatedUser]);

  const logoutUser = useCallback(
    (redirectUrl) => {
      setUser(null);
      setAccessToken(null);
      setIsAuthenticated(false);
      localStorage.clear();
      signOutUser();
      if (redirectUrl) navigate(redirectUrl);
    },
    [setUser, setAccessToken, setIsAuthenticated, navigate]
  );

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUser(user?.user || null);
    setAccessToken(user?.token || null);
  }, [setUser, setAccessToken]);

  useEffect(() => {
    const unsubscribe = onAuthStateChangedListener((authuser) => {
      if (authuser) {
        createUserDocumentFromAuth(authuser, user);
        setFsUser(authuser);
      }
    });
    return unsubscribe;
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        fsUser,
        logoutUser,
        persistUser,
        accessToken,
        isAuthenticated,
        setIsAuthenticated,
        persistUpdatedUser,
        validateUser,
        setFsUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
