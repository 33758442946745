import React, { Fragment } from "react";
import { Transition, Menu } from "@headlessui/react";

export default function OccupationDropdown({ selected, onSelected }) {
  const occupations = ["Student", "Employed", "Self Employed"];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <Menu as="div" className="relative w-full mb-10">
      <div className="flex flex-col">
        <label
          htmlFor="send-to"
          className="duration-300 origin-0 block text-sm text-gray-600 pb-3"
        >
          Occupation Status
        </label>
        <Menu.Button className="inline-flex justify-between items-center w-full p-4 border rounded-md border-gray-300  text-sm text-black focus:outline-none focus:ring-0 focus:ring-offset-0">
          {selected && selected !== "" ? selected : "Select Option"}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="-mr-1 ml-2 h-5 w-5 text-icon"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-50 h-30 origin-top-right absolute right-0 mt-2 w-full rounded-none shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-auto">
          <div className="divide-y divide-icon divide-opacity-10">
            {occupations.map((occupation, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <li
                    onClick={function () {
                      onSelected(occupation);
                    }}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      " flex flex-row text-primary px-3 py-4 text-xsm cursor-pointer"
                    )}
                  >
                    <span>{occupation}</span>
                  </li>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
