import axios from "axios";
import { useState, createContext, useEffect, useCallback } from "react";
import { runMultiBatchRequest } from "../api/wordpress";

export const FormFieldsContext = createContext({});

var buildRequestObjList = [
    {
        path: '/fetch-acf-field',
        method: 'get',
        data: {
            field: "field_62d48f46a7d5f", //property_types
        }
    },
    {
        path: '/fetch-acf-field',
        method: 'get',
        data: {
            field: "field_62d403af13bb8", //gender
        },
    },
    {
        path: '/fetch-acf-field',
        method: 'get',
        data: {
            field: "field_626f69f214971", //amenities
        }
    }
]

export const FormFieldsContextProvider = ({ children }) => { 
    var token = JSON.parse(localStorage.getItem("user"))?.token;
    const [allFields] = useState(JSON.parse(localStorage.getItem('fields')) || null);
    const [propertyTypes, setPropertyTypes] = useState(JSON.parse(localStorage.getItem('fields'))?.propertyTypes || null);
    const [genders, setGenders] = useState(JSON.parse(localStorage.getItem('fields'))?.genders || null);
    const [amenitiesOptions, setAmenitiesOptions] = useState(JSON.parse(localStorage.getItem('fields'))?.amenitiesOptions || null);
    const [budgetRanges, setBudgetRanges] = useState(JSON.parse(localStorage.getItem('fields'))?.budgetRanges || null);

    const setAllFormFields  =  useCallback(async () => {
        if(!allFields){
            await runMultiBatchRequest(token, buildRequestObjList).then(
                axios.spread((...responses) => {
                    var parsedAmenities = [];
                    var parsedGenders = [];
                    var parsedPropertyTypes = [];
                    var parsedBudgetRanges = [];
                    responses.forEach((response, index) => {
                        const { data } = response;
                        if (index === 0) { // property_type
                            parsedPropertyTypes = data.map(
                                ({ term_id: value,
                                    ...rest
                                }) => ({
                                    value,
                                    ...rest
                                }
                                ));
                            setPropertyTypes(parsedPropertyTypes);
                        } else if (index === 1) { //gender
                            Object.keys(data).forEach(key => {
                                parsedGenders.push({
                                    "name": data[key],
                                    "value": key
                                });
                            });
                            setGenders(parsedGenders)
                        } else if (index === 2) { // amenities
                            Object.keys(data).forEach(key => {
                                parsedAmenities.push({
                                    "name": data[key],
                                    "value": key
                                });
                            });
                            setAmenitiesOptions(parsedAmenities);
                        } 
                    });
                    
                    for(let i=50000 ; i <= 500000; i+=50000){
                        parsedBudgetRanges.push({
                            "name": `${i}`,
                            "value": i
                        })
                    }
                    setBudgetRanges(parsedBudgetRanges); 
                    
                    localStorage.setItem('fields', JSON.stringify({amenitiesOptions:parsedAmenities, genders:parsedGenders, propertyTypes:parsedPropertyTypes, budgetRanges:parsedBudgetRanges }));
                }
            ));
        
        }
    }, [allFields, token]);

    useEffect(() => {
    }, [allFields]);

    return (
        <FormFieldsContext.Provider
          value={{
            propertyTypes,
            genders,
            amenitiesOptions, 
            budgetRanges,
            setAllFormFields
          }}
        >
          {children}
        </FormFieldsContext.Provider>
      );
};