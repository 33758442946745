import { Link } from 'react-router-dom';

const RegularButton = ({exticon, text, linkTo, ...otherParams}) => {
    return (
        <div className="text-[12px]" {...otherParams} >
            <Link to={linkTo ? linkTo : '##'}>
                <div className="w-full rounded-md md:rounded-lg flex text-center items-center text-white px-8 py-[6px] bg-primary hover:bg-blue-900">
                    {exticon ? exticon : ''}
                    {text ? text : `Create post`}
                </div>
            </Link>
        </div>
    );
}

export default RegularButton;