import PublishPropertyForm from "../../../components/forms/publish-property/publish-property-form.component";


const PublishPropertyScreen = () => {
    return(
        <div className="mt-2 mb-5">
            <PublishPropertyForm />
        </div>
    );
}

export default PublishPropertyScreen;