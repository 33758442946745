import { Link } from "react-router-dom";
import ResetPasswordForm from "../../components/forms/reset-password/reset-password-form.component";
import logo from "../../assets/images/logoimg.png";
import { useSearchParams } from "react-router-dom";
import SetPasswordForm from "../../components/forms/reset-password/set-password-form.component";
import image1 from "../../assets/images/forgot-bg.jpg";
import image2 from "../../assets/images/reset-bg.jpg";
import RuumiesLogo from "../../assets/images/ruumies-white-logo.svg";
import { useEffect } from "react";

const PasswordResetScreen = () => {
  const [searchParams] = useSearchParams();

  const oobCode = searchParams.get("oobCode");

  useEffect(() => {
    document.title =
      oobCode && oobCode !== "" ? "Set new password" : "Forgot Password";
  });

  return (
    <>
      <div
        className="hidden md:inline-block md:w-1/3 h-full bg-cover bg-center"
        style={{
          backgroundImage: `url(${oobCode && oobCode !== "" ? image2 : image1})`,
        }}
      >
        <div className="flex px-6 py-10">
          <img src={RuumiesLogo} alt="Ruumies Logo" width={110} height={23} />
        </div>
      </div>
      <div className="inline-block w-full md:w-2/3 h-full pt-10 overflow-auto">
        <div className="p-5 pt-0 lg:w-[500px] md:w-2/3 mx-auto">
          <div className="w-full flex flex-col justify-center">
            <div className="mb-8 flex items-start justify-start">
              <img src={logo} alt="logo" width="110" height="23" />
            </div>
            <h2 className="text-3xl mt-10 mb-8 header-font font-semibold">
              {oobCode && oobCode !== ""
                ? "Set new password"
                : "Forgot Password?"}
            </h2>
            {oobCode && oobCode !== "" ? (
              <SetPasswordForm />
            ) : (
              <ResetPasswordForm />
            )}
            <div className="mt-5 mb-10 text-center">
              <p className="text-md text-[#656565] font-normal">
                Go back to
                <Link className="text-secondary" to="/login">
                  {" "}
                  login page?
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordResetScreen;
