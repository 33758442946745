import { Link, useMatch, useResolvedPath, useLocation } from "react-router-dom";

const MenuItem = ({menuItem}) => {
    const {title, icon, path} = menuItem;
    const resolved = useResolvedPath('');
    const location = useLocation();
    let match = useMatch({ path: resolved.pathname + path, end: true });

    if(path==='/property-list' && location.pathname.indexOf('/properties') !== -1 ){
        match = true;
    } 
    
    return(
        <li className={"svg-menu-item svg-menu-special hover:text-secondary mb-9 " + ( match ? 'text-secondary highlighted-menu-item' : '')}>
            <Link className="flex pt-1" to={`${resolved.pathname}${path}`}>
                {icon}{title}
            </Link>
        </li>   
    );
}

const DashboardMenu = ({menuItemList, ...otherParams}) => {
    return (
        <ul className="flex flex-col text-neutral-500 lg:text-white font-light" {...otherParams}>
            {menuItemList.map((item, index) => {
                return( <MenuItem key={index} menuItem={item} /> )
            })}
        </ul>
    );
}

export default DashboardMenu;