import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { ReactComponent as CheckIcon } from '../../../assets/vectors/Vectorcheck.svg';
import { ReactComponent as SelectorIcon } from '../../../assets/vectors/selectoricon.svg';
import { ReactComponent as MarkSvg } from '../../../assets/vectors/place_black_24dp-1.svg';
import { useEffect } from 'react';

const ListBoxDropdown = ({lists, icon, getSelected, filters, dataKey}) => {
    const [selected, setSelected] = useState(lists[0]);

    useEffect(() => {
        getSelected({ ...filters, [dataKey] : selected.value});
    }, [selected, getSelected, dataKey])

    return(
        <Listbox value={selected} onChange={setSelected}>
            <div className="relative mt-1">
                <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-3 pl-6 pr-10 text-left border border-stone-200 border-sm sm:text-[12px]">
                    <span className="pointer-events-none absolute inset-y-0 left-3 flex items-center ">
                    {icon ? icon :<MarkSvg className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                    </span>
                    <span className="block truncate ml-5">{selected.name}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                         <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-[9]">
                        
                        {lists.map((item, Idx) => (
                            <Listbox.Option key={Idx} className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-stone-100 text-black-900' : 'text-gray-500'}`} value={ item }>
                            {({ selected }) => (
                                <>
                                    <span className={`block truncate ${ selected ? 'font-light' : 'font-light'}`}>
                                        {item.name}
                                    </span>
                                    {selected ? (
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    ) : null}
                                </>
                            )}
                            </Listbox.Option>
                        ))}

                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    )
}

export default ListBoxDropdown;