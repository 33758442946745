import axios from "axios";
import { useEffect, useState } from "react";
import { runMultiBatchRequest } from "../../api/wordpress";
import PageLoader from "../../components/loaders/page-loader.component";
import { isArray, urlFromObject } from "../../utils/functions";
import NoPaymentHistoryScreen from "./params/no-payment-history.screen";
import PropertyHistoryList from "./params/payment-history-list.screen";

const PaymentHistoryScreen = () => {
  document.title = "Payment History";
  const [queryParams] = useState({ per_page: 12, page: 1 });
  const [transactionList, setTransactionList] = useState([]);
  const [loader, setLoader] = useState(true);
  const token = JSON.parse(localStorage.getItem("user"))?.token;
  const email = JSON.parse(localStorage.getItem("user"))?.user?.user_email;

  useEffect(() => {
    const filterObj = {
      paying_customer: encodeURI(email),
      recieving_customer: encodeURI(email),
    };
    const newQueryParams = { ...queryParams, ...filterObj };
    setLoader(true);

    const requestHistory = async () => {
      await runMultiBatchRequest(token, [
        {
          path: "/transaction?" + urlFromObject(newQueryParams),
          method: "get",
          data: "",
        },
      ]).then(
        axios.spread((...responses) => {
          responses.forEach((response) => {
            let arr = [];
            if (!isArray(response.data)) {
              if (Number.isInteger(Number(Object.keys(response.data)[0]))) {
                arr = Object.keys(response.data).map((key) => {
                  return response.data[key];
                });
              } else {
                arr[0] = response.data;
              }
            } else {
              arr = response.data;
            }

            setTransactionList(arr);
            setLoader(false);
          });
        })
      );
    };

    requestHistory().catch((e) => {
      console.log(e);
    });
  }, []);

  return (
    <>
      {transactionList.length === 0 ? (
        <>
          {loader ? (
            <PageLoader
              size="150px"
              className="p-10 w-full flex justify-center"
            />
          ) : (
            <NoPaymentHistoryScreen />
          )}
        </>
      ) : (
        <PropertyHistoryList data={transactionList} />
      )}
    </>
  );
};

export default PaymentHistoryScreen;
