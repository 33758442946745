import { 
    FacebookShareButton, 
    LinkedinShareButton, 
    TwitterShareButton, 
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon } from 'react-share'

const SocialIcons = () => {
    return(
        <div className="md:flex gap-2 hidden ">
            <FacebookShareButton className='' ><FacebookIcon size={32} round={true} /></FacebookShareButton>
            <LinkedinShareButton className=''><LinkedinIcon size={32} round={true} /></LinkedinShareButton>
            <TwitterShareButton className='' ><TwitterIcon size={32} round={true} /></TwitterShareButton>
        </div>
    )
}

export default SocialIcons;