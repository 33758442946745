import Lottie from "lottie-react";
import loader from '../../assets/lottie/7774-loading.json';
import logo from '../../assets/images/logoimg.png';

const PreLoader = () => {
    return(
        <div className='h-screen w-full flex justify-center items-center'>
            <Lottie  animationData={loader} loop={true} />
            <img className="absolute left-[44%] bottom-20 w-[200px] z-9999 " src={logo} alt="logo ruumies" />
        </div>
    )
}

export default PreLoader;