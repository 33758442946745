import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Lottie from "lottie-react";
import loader from '../../assets/lottie/7774-loading.json';
import logo from '../../assets/images/logoimg.png';

export default function BgModal({modalState, children, ...otherParams}) {
    const [, setOpen] = useState(modalState);
    const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={modalState} as={Fragment}>
      <Dialog as="div" className="relative z-[1000]" initialFocus={cancelButtonRef} onClose={setOpen} {...otherParams}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[white] bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center md:min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative rounded-lg text-left justify-center flex h-screen overflow-hidden transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                {
                    children ? children :
                    <>
                    <Lottie className="mt-[30%] w-[200px] h-[200px]" animationData={loader} loop={true} />
                    <img className="absolute left-[32%] bottom-20 w-[200px] z-9999 " src={logo} alt="logo ruumies" />
                    </> 
                }
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}