import image1 from "../../assets/images/ruum-search.svg";
import image2 from "../../assets/images/ruum-find.svg";

import PreLoader from "../preloader/preloader.screen";

import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/user.context";
import { updateWpUser } from "../../api/wordpress";

import SwitchRoleBox from "../../components/image-boxes/switch-role-image-box/switch-role-box.component";
import BgModal from "../../components/modals/background-modal.component";
import ErrorModal from "../../components/modals/error-modal.component";
import bg1 from "../../assets/images/d-section-one.jpg";
import bg2 from "../../assets/images/d-section-two.jpg";
import RuumiesLogo from "../../assets/images/ruumies-white-logo.svg";

const AccountChooser = () => {
  const {
    accessToken,
    persistUpdatedUser,
    isAuthenticated,
    setIsAuthenticated,
    validateUser,
    logoutUser,
  } = useContext(UserContext);
  const navigator = useNavigate();

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);

  const handleClick = async (event) => {
    let role = event.currentTarget.getAttribute("role");
    let isCheckedIn = localStorage.getItem("checkedIn");
    let destination =
      (role === "owner" && "/dashboard/post-requests") ||
      (role === "tenant" && "property-list") ||
      "";
    const formData = new FormData();
    formData.append("viewing_as", role);
    try {
      setLoader(true);
      const response = await updateWpUser(accessToken, formData);
      persistUpdatedUser(response.data);
      setLoader(false);
      if (isCheckedIn === null && isCheckedIn !== 1) {
        localStorage.setItem("checkedIn", 1);
        if (role === "owner") {
          navigator(`/dashboard/my-properties?action=add-new`);
        } else if (role === "tenant") {
          navigator(`/dashboard/my-post-requests?action=add-new`);
        }
      } else {
        navigator(destination);
      }
    } catch (error) {
      setLoader(false);
      const { code, message } = error.response.data
        ? error.response.data
        : error;

      setError({
        code: code,
        message: message,
        status: true,
      });
    }
  };

  useEffect(() => {
    document.title = "Role Switcher";
    const isValidUser = async () => {
      await validateUser();

      setIsAuthenticated(true);
    };
    isValidUser().catch((error) => {
      logoutUser("/login?acess=failed&reason=access-error");
    });
  }, []);

  return !isAuthenticated ? (
    <PreLoader />
  ) : (
    <div className="h-screen w-screen flex">
      <div
        className="block md:inline-block md:w-1/2 h-full bg-cover bg-center"
        style={{
          backgroundImage: `url(${bg1})`,
        }}
      >
        <div className="h-full flex flex-col justify-between px-6 py-10">
          <div className="flex flex-col space-y-6">
            <img src={RuumiesLogo} alt="Ruumies Logo" width={110} height={23} />
            <div className="flex flex-col">
              <h4 className="text-white text-[28px]">Select what you need</h4>
              <p className="text-white text-[16px]">
                You can easily switch between accounts once you are logged in
              </p>
            </div>
          </div>
          <div></div>
          <div></div>
          <div></div>
          <SwitchRoleBox
            onClick={handleClick}
            role="owner"
            imageSrc={image1}
            text="I need a rummate"
            description="Click to find the perfect Ruumie you can be comfortable with, and share the bills."
          />
          <div className="-mt-14 block md:hidden">
            <SwitchRoleBox
              onClick={handleClick}
              role="tenant"
              imageSrc={image2}
              text="I need a ruum"
              description="Click to find the most suitable and budget-friendly apartments in location of choice. "
            />
          </div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div
        className="hidden md:inline-block md:w-1/2 h-full bg-cover bg-center"
        style={{
          backgroundImage: `url(${bg2})`,
        }}
      >
        <div className="h-full flex flex-col justify-between px-6 py-10">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div>
            <div className="mb-28"></div>
            <SwitchRoleBox
              onClick={handleClick}
              role="tenant"
              imageSrc={image2}
              text="I need a ruum"
              description="Click to find the most suitable and budget-friendly apartments in location of choice. "
            />
          </div>
          <div></div>
          <div></div>
        </div>
      </div>

      <BgModal modalState={loader} />
      <ErrorModal
        action={() => {
          setError(false);
        }}
        state={error}
        title="An Error Occured!"
        description="There was an unknown error in your user role change, please try again."
      />
    </div>
  );
};

export default AccountChooser;
