import moreIcon from "../../../assets/vectors/more.svg";
import { ReactComponent as Edit2Svg } from "../../../assets/vectors/edit-2.svg";
import { ReactComponent as TrashSvg } from "../../../assets/vectors/trash.svg";
import { ReactComponent as ShareSvg } from "../../../assets/vectors/share.svg";
import { ReactComponent as MarkerSvg } from "../../../assets/vectors/place_black_24dp-1.svg";
import { ReactComponent as BuildingSvg } from "../../../assets/vectors/Group-2104.svg";
import { ReactComponent as TagSvg } from "../../../assets/vectors/Frame.svg";

import Moment from "react-moment";

import AlignedImageBox from "../../image-boxes/aligned-image-box/aligned-image-box";
import ProfileDropdownMenu from "../../menus/profile-dropdown-menu/profile-dropdown-menu.component";
import ImageDropdown from "../../dropdowns/image-dropdown/image-dropdown.component";
import SimpleIconBox from "../../image-boxes/simple-icon-box/simple-icon-box.component";
import { nairaFormart, truncateWords } from "../../../utils/functions";

const MyPostRequestCard = ({ data }) => {
  const ItemList = [
    {
      title: "Edit",
      icon: <Edit2Svg className="inline-block mr-2 h-4" />,
      path: "/?action=edit&id=" + data?.slug,
    },
    {
      title: "Delete",
      icon: <TrashSvg className="inline-block -ml-1 mr-1 h-4" />,
      path: "/?action=delete&id=" + data?.slug,
    },
    {
      title: "Share",
      icon: <ShareSvg className="inline-block mr-2 pr-[1px] h-4" />,
      path: "/?action=share&id=" + data?.slug,
    },
  ];

  const location = data?.acf?.city + ", " + data?.acf?.state;

  return (
    <div className="flex flex-col p-3 md:p-7 shadow-sm border border-stone-100 md:border-stone-200 rounded-sm md:rounded-md gap-6">
      <div className="flex justify-between items-center">
        <AlignedImageBox
          check={true}
          profileImage={data?.author?.profile_photo}
          text={data?.author?.full_name}
          subtext={"@" + data?.author?.display_name}
        />
        <ImageDropdown
          panelElement={<ProfileDropdownMenu menuItemList={ItemList} />}
          image={{ src: moreIcon, size: 24 }}
          nocaretIcon={" "}
          appendClass={"-translate-x-[28%] -mt-[2px]"}
        />
      </div>
      <p
        className="font-light text-stone-500 text-[12px] pr-3"
        dangerouslySetInnerHTML={{ __html: data?.content }}
      />
      <div className="flex gap-2 md:gap-5 text-[11px] md:text-[14px] -mb-1 md:-mb-4">
        {data?.acf?.city && (
          <SimpleIconBox
            key={3}
            icon={<MarkerSvg />}
            text={
              <p className="text-stone-800 -ml-1">
                {truncateWords(location, 24)}
              </p>
            }
          />
        )}
        {(data?.property_type || data?.acf?.property_type) && (
          <SimpleIconBox
            key={4}
            icon={<BuildingSvg className="h-4" />}
            text={
              <p className="text-stone-800 -ml-1">
                {data?.property_type || data?.acf?.property_type}
              </p>
            }
          />
        )}
        {data?.acf?.budget && (
          <SimpleIconBox
            key={5}
            icon={<TagSvg className="h-4" />}
            text={
              <p className="text-stone-800 -ml-1">
                {nairaFormart(data?.acf?.budget)}
              </p>
            }
          />
        )}
      </div>
      <p className="block text-right font-extralight text-[12px] text-stone-500">
        <Moment fromNow>{data?.modified}</Moment>
      </p>
    </div>
  );
};

export default MyPostRequestCard;
