
const NotFoundRender = ({ message, icon, state }) => {
    return (
        <div className="flex justify-center">
            <div className="w-[500px] h-[500px] bg-red-200 rounded-full flex justify-center items-center ">
                <div className="shadow-lg w-[400px] h-[400px] bg-white flex flex-col justify-center items-center gap-3">
                    <video loop autoPlay style={{height: "210px", width: "210px"}}>
                        <source
                            width={"80px"}
                            height={"80px"}
                            src={require("../../assets/videos/87830-empty-search.mp4")} 
                            type="video/mp4"
                        />
                    </video>
                    <h2 className="text-2xl center font-semibold">Sorry No Results Found :(</h2>
                    <p className="font-light">{message}</p>
                </div>
            </div>
        </div>
    )
}

export default NotFoundRender;