import MyPropertyCardItem from "../../card-items/property-card-item/my-property-card-item.component";

const MyPropertyCardLoop = ({ propertyList }) => {
  return (
    <div
      className="min-w-full flex flex-col mt-5 p-5 overflow-x-auto"
      style={{ minHeight: "calc(100vh - 70px)" }}
    >
      <div
        className="table-header flex font-normal header-font text-[14px] gap-8 "
        style={{ minWidth: "max-content" }}
      >
        <div className="w-[3%]">S/N</div>
        <div className="w-[27%]">Property</div>
        <div className="w-[25%]">Address</div>
        <div className="w-[15%]">Price</div>
        <div className="w-[20%] text-center">Status</div>
        <div className="w-[10%]"></div>
      </div>
      <div
        className="table-header flex flex-col font-light gap-2 mt-2 text-[14px]"
        style={{ minWidth: "max-content" }}
      >
        {propertyList.map((item, index) => {
          return (
            <MyPropertyCardItem key={item.id} data={item} sn={index + 1} />
          );
        })}
      </div>
    </div>
  );
};

export default MyPropertyCardLoop;
