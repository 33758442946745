import { Link, useResolvedPath, useSearchParams } from "react-router-dom";

const MenuItem = ({menuItem}) => {
    const {title, icon, path} = menuItem;
    const resolved = useResolvedPath('');
    const [params] = useSearchParams('');
    let match  = false;
    if(path.indexOf(params.get('action')) !== -1 || (params.get('action') == null && path==='') ){

        match = true;
    }

    
    return(
        <li className={"svg-menu-item hover:text-secondary mb-9 " + ( match ? 'text-secondary highlighted-menu-item' : '')}>
            <Link className="flex pt-1" to={`${resolved.pathname}${path}`}>
                {icon}{title}
            </Link>
        </li>   
    );
}

const AccountMenu = ({menuItemList}) => {
    return (
        <ul className="flex flex-col text-neutral-500 font-light">
            {menuItemList.map((item, index) => {
                return( <MenuItem key={index} menuItem={item} /> )
            })}
        </ul>
    );
}

export default AccountMenu;