import { useEffect, useContext } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import image from "../../assets/images/auth-image.png";
import { UserContext } from "../../contexts/user.context";

const AuthLayout = () => {
  const { user } = useContext(UserContext);
  const navigator = useNavigate();

  useEffect(() => {
    if (
      user != null &&
      (user.date_of_birth == undefined || user.date_of_birth == null)
    ) {
      navigator("/complete-account");
    } else if (user != null) {
      navigator("/dashboard");
    }
  }, []);

  return (
    <div className="h-screen w-screen flex">
      <Outlet />
    </div>
  );
};

export default AuthLayout;
