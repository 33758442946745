import React, { useEffect, useState, useContext } from "react";
import { FormFieldsContext } from "../../../contexts/form-fields.context";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  createProperty,
  getSingleProperty,
  updateProperty,
} from "../../../api/wordpress";

import SearchLocationInput from "../../fields/google-maps-search-input/google-maps-search-input.component";
import InputField from "../../fields/input-field/form-input.component";
import SelectField from "../../fields/select-field/form-select.component";
import BtnLoader from "../../loaders/btn-loader.component";
import PageLoader from "../../loaders/page-loader.component";
import ErrorTextPane from "../../text-boxes/info-panes/error-status-pane.component";
import SuccessModal from "../../modals/success-modal.component";
import CurrencyInputField from "../../fields/input-field/currency-input.component";
import axios from "axios";

const defaultErrorObject = {
  code: "",
  status: false,
  message: "",
};

const defaultFormFields = {
  id: false,
  price: 0,
  post_title: "",
  content: "",
  other_amenity_field: "",
  address: "",
  property_type: "",
  amenities: [],
  preferred_gender: "",
  gallery: [],
  state: "",
  city: "",
  street: "",
  lga: "",
  landmark: "",
};

const PublishPropertyForm = ({ setReloadPosts, setParams }) => {
  const [params] = useSearchParams();

  const [addressObj, setAddressObj] = useState();

  const { amenitiesOptions, genders, propertyTypes } =
    useContext(FormFieldsContext);
  const token = JSON.parse(localStorage.getItem("user"))?.token;
  const user_email = JSON.parse(localStorage.getItem("user"))?.user?.user_email;
  const user_nickname = JSON.parse(localStorage.getItem("user"))?.user
    ?.nickname;
  const user_firstname = JSON.parse(localStorage.getItem("user"))?.user
    ?.first_name;
  const user_lastname = JSON.parse(localStorage.getItem("user"))?.user
    ?.last_name;
  const phone_number = JSON.parse(localStorage.getItem("user"))?.user
    ?.phone_number;

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [enableOtherField, setEnableOtherField] = useState(false);
  const [btnloader, setBtnLoading] = useState(false);
  const [modalState, setModalState] = useState(false);

  const [formFields, setFormFields] = useState(defaultFormFields);
  const [files, setFile] = useState([]);
  const [imageMessage, setImageMessage] = useState("");

  const {
    id,
    post_title,
    other_amenity_field,
    price,
    content,
    property_type,
    address,
    amenities,
    preferred_gender,
    city,
    state,
    lga,
    landmark,
    street,
  } = formFields;

  const [errorObj, setLoginError] = useState(defaultErrorObject);
  const [stateOptions, setStateOptions] = useState([]);
  const [lgaOptions, setLgaOptions] = useState([]);

  useEffect(() => {
    fetchStates();
  }, []);

  const getToken = async () => {
    const response = await axios.post("https://api.qoreid.com/token", {
      clientId: "RJQESSU6GNNHO7ZT0QWD",
      secret: "65aa73f854a342b5b21c08375e174bfa",
    });
    const accessToken = response.data.accessToken;
    return accessToken;
  };

  const fetchStates = async () => {
    const token = await getToken();
    const statesResponse = await axios.get(
      "https://api.qoreid.com/v1/countries/1/states",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setStateOptions(
      statesResponse.data.map((state) => {
        return { value: `${state.name}-${state.id}`, name: state.name };
      })
    );
  };

  const fetchLgas = async (stateId) => {
    const token = await getToken();
    const lgasResponse = await axios.get(
      `https://api.qoreid.com/v1/countries/1/states/${stateId}/lgas`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setLgaOptions(
      lgasResponse.data.map((lga) => {
        return { value: lga.name.trim(), name: lga.name };
      })
    );
  };

  const handleFile = (e) => {
    setImageMessage("");
    let file = e.target.files;

    for (let i = 0; i < file.length; i++) {
      const fileType = file[i]["type"];
      const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
      if (!validImageTypes.includes(fileType)) {
        setImageMessage("only images accepted");
      }
    }
    if (files.filter((f) => f.name !== f)) {
      setFile([...files, ...file]);
    }
    console.log(file);
  };

  const removeImage = (i) => {
    setFile(files.filter((x) => x.name !== i));
  };

  const setDefaultFormFields = (data, propertyTypes, amenities) => {
    let tempFormFields = {};
    Object.keys(defaultFormFields).forEach((key) => {
      if (data[key] !== undefined) {
        if (key === "property_type") {
          tempFormFields[key] = propertyTypes?.find(
            (item) => item.name === data[key]
          )["value"];
        } else if (key === "id") {
          tempFormFields[key] = data[key];
        } else {
          tempFormFields[key] = data[key].replace(/<\/?[^>]+(>|$)/g, "");
        }
      } else if (key === "post_title") {
        tempFormFields[key] = data["title"];
      } else if (data["acf"][key] !== undefined) {
        if (key === "amenities") {
          tempFormFields[key] = [];
          data["acf"][key].forEach((item) => {
            tempFormFields[key].push(
              amenities?.find((obj) => obj.value === item["value"])["value"]
            );
          });
          const otherAmenityIsChecked = data["acf"][key].find(
            (obj) => obj.value === "other"
          );

          if (
            otherAmenityIsChecked !== undefined &&
            otherAmenityIsChecked !== null
          ) {
            setEnableOtherField(true);
          }
        } else if (key === "gallery") {
          tempFormFields[key] = [];
          data["acf"][key].forEach((item) => {
            tempFormFields[key].push(item["full_image_url"]);
          });
        } else {
          tempFormFields[key] = data["acf"][key];
        }
      }
    });

    setFormFields(tempFormFields);
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "amenities[]") {
      if (value === "other") {
        setEnableOtherField(!enableOtherField);
      }
      if (formFields["amenities"].includes(value)) {
        const clonedFormFields = { ...formFields };
        clonedFormFields["amenities"].splice(
          clonedFormFields["amenities"].findIndex(
            (amenity) => amenity === value
          ),
          1
        );
        setFormFields(clonedFormFields);
      } else {
        setFormFields({
          ...formFields,
          amenities: [...formFields["amenities"], value],
        });
      }
    } else {
      if (name === "state") {
        setLgaOptions([]);
        const splittedValue = value.split("-");
        value = splittedValue[0].trim();
        fetchLgas(splittedValue[1]);
      }
      setFormFields({ ...formFields, [name]: value });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (price < 10000) {
      setLoginError({
        code: 500,
        message: "Price must be in the range of N10,000 above.",
        status: true,
      });
      return;
    }
    const formComponent = event.currentTarget;
    formComponent.querySelector("#create_property").disabled = "disabled";

    setBtnLoading(true);

    const token = await getToken();
    const addressResponse = await axios.post(
      "https://api.qoreid.com/v1/addresses",
      {
        customerReference: user_nickname,
        street: street,
        lgaName: lga,
        stateName: state,
        landmark: landmark,
        city: city,
        applicant: {
          firstname: user_firstname,
          lastname: user_lastname,
          phone: phone_number,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(addressResponse);

    try {
      const formData = new FormData(event.target);
      formData.append("user_email", user_email);
      // if (addressObj) {
      //   formData.append("address", addressObj["formatted_address"]);
      // } else {
      //   formData.append("address", address);
      // }
      formData.append("file_key", "gallery");
      formData.append("field_key", "field_626f6d4b0f2a8");
      if (params.get("action") === "edit") {
        formData.append("id", id);
        await updateProperty(token, formData);
      } else {
        await createProperty(token, formData);
      }
      setBtnLoading(false);
      setModalState(true);
    } catch (error) {
      setBtnLoading(false);
      const { code, message } = error.response.data
        ? error.response.data
        : error;

      setLoginError({
        code: code,
        message: message,
        status: true,
      });
      document
        .getElementById("dashboard-content")
        .scrollTo(0, 0, { behavior: "smooth", block: "start" });
      formComponent.querySelector("#create_property").disabled = false;
    }
  };

  useEffect(() => {
    if (params.get("action") === "edit") {
      const requests = async () => {
        await getSingleProperty(token, {
          include: [params.get("id")],
        }).then((response) => {
          const { data } = response;
          setDefaultFormFields(data, propertyTypes, amenitiesOptions);
          setLoader(false);
        });
      };

      requests().catch((error) => {
        console.log(error);
      });
    } else {
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    var components = {};
    if (addressObj) {
      var address_components = addressObj.address_components;
      address_components.forEach((address_component) => {
        address_component["types"].forEach((type) => {
          components[type] = address_component.long_name;
        });
      });
    }
  }, [addressObj]);

  return (
    <>
      {loader ? (
        <PageLoader size="150px" className="p-10 w-full flex justify-center" />
      ) : (
        <div className="md:w-[400px]" style={{ margin: "auto" }}>
          <form id="create-post-request_form" onSubmit={handleSubmit}>
            <ErrorTextPane
              status={errorObj.status}
              message={errorObj.message}
            />
            <p className="block text-md font-normal mb-6 ">
              {params.get("action") !== "edit"
                ? "Fill in the details below to upload a property"
                : "Edit the details of your property"}
            </p>

            <InputField
              label="Name of property"
              type="text"
              name="post_title"
              value={post_title}
              required
              onChange={handleChange}
            />

            <SelectField
              options={propertyTypes}
              onChange={handleChange}
              label={"Type of Property"}
              name="property_type"
              required
              defaultValue={property_type}
            />

            <SelectField
              options={stateOptions}
              onChange={handleChange}
              label={"State"}
              name="state"
              required
              defaultValue={""}
            />

            {!lgaOptions.length ? (
              <></>
            ) : (
              <SelectField
                options={lgaOptions}
                onChange={handleChange}
                label={"LGA"}
                name="lga"
                required
                defaultValue={""}
                id="lgaOption"
              />
            )}

            <InputField
              label="City"
              type="text"
              name="city"
              value={city}
              required
              onChange={handleChange}
            />

            <InputField
              label="Street"
              type="text"
              name="street"
              value={street}
              required
              onChange={handleChange}
            />

            <InputField
              label="Landmark"
              type="text"
              name="landmark"
              value={landmark}
              required
              onChange={handleChange}
            />

            <CurrencyInputField
              label="Cost Of Rent (per year)"
              name="price"
              value={price}
              required
              onChange={(v) => setFormFields({ ...formFields, price: v })}
            />

            <SelectField
              options={genders}
              type="radio"
              onChange={handleChange}
              label={"Preferred Gender"}
              name="preferred_gender"
              required
              defaultValue={preferred_gender}
            />

            <InputField
              label="Add a brief desccription of the property"
              type="textarea"
              name="content"
              value={content}
              required
              onChange={handleChange}
              placeholder="Feel free to talk about your occupation, likes, dislike and ideal property type."
            />

            <SelectField
              options={amenitiesOptions}
              type="checkbox"
              onChange={handleChange}
              label={"Tick the amenities available"}
              name="amenities"
              required
              defaultValue={amenities}
            />

            {enableOtherField && (
              <InputField
                label="Other Amenities"
                type="text"
                name="other_amenity_field"
                value={other_amenity_field}
                required
                onChange={handleChange}
              />
            )}

            <div
              className=" w-full relative flex flex-col mb-10
                        "
            >
              <p className="block text-sm text-gray-600 pb-2 text-left">
                Add Images of property
              </p>
              <div className="flex gap-2 mt-2">
                {files.map((file, key) => {
                  return (
                    <div key={key} className="overflow-hidden relative">
                      <span
                        onClick={() => {
                          removeImage(file.name);
                        }}
                        className="absolute right-1 hover:text-white cursor-pointer text-sm b"
                      >
                        X
                      </span>
                      <img
                        className="h-20 w-20 rounded-md "
                        alt={`img-upload-preview-${key}`}
                        src={URL.createObjectURL(file)}
                      />
                    </div>
                  );
                })}
              </div>
              <span className="flex justify-center items-center text-[12px] mb-1 text-red-500">
                {imageMessage}
              </span>
              <div className="h-32 w-full relative cursor-pointer border-2 items-center rounded-md bg-gray-300 border-gray-400 border-dotted">
                <input
                  type="file"
                  onChange={handleFile}
                  className="h-full w-full bg-green-200 opacity-0 z-10 absolute cursor-pointer"
                  multiple="multiple"
                  name="files[]"
                  accept="image/*"
                />
                <div className="h-full w-full bg-gray-200 absolute z-1 flex justify-center items-center top-0 cursor-pointer">
                  <div className="flex flex-col">
                    <i className="mdi mdi-folder-open text-[30px] text-gray-400 text-center"></i>
                    <span className="text-[12px]">{`Drag and Drop a file`}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="field_wrapper mb-5">
              <button
                type="submit"
                name="submit"
                id="create_property"
                disabled={
                  post_title === "" ||
                  price === "" ||
                  content === "" ||
                  property_type === "" ||
                  amenities === "" ||
                  preferred_gender === "" ||
                  state === "" ||
                  city === "" ||
                  street === "" ||
                  lga === ""
                }
                className="w-full rounded-md text-white p-4 bg-primary hover:bg-blue-900 font-semibold disabled:cursor-not-allowed"
              >
                {btnloader && <BtnLoader />}
                Upload Property
              </button>
            </div>
          </form>
          <SuccessModal
            action={() => {
              setModalState(false);
              if (params.get("action") === "edit") {
                params.delete("action");
                params.delete("id");
                setParams(params);
                setReloadPosts(true);
              }
              if (params.get("action") === "add-new") {
                navigate("/dashboard/my-properties");
              }
            }}
            state={modalState}
            title="Request posted successfully"
            description="Your property has been successfully posted, soon you’ll have ruum seekers reach out to you."
          />
        </div>
      )}
    </>
  );
};

export default PublishPropertyForm;
