import { Link, useNavigate } from "react-router-dom";
import ImageSlider from "../../carousels/image-slider/image-slider.component";
import SimpleIconBox from "../../image-boxes/simple-icon-box/simple-icon-box.component";
import { ReactComponent as ChatSvg } from "../../../assets/vectors/message-text.svg";
import { nairaFormart } from "../../../utils/functions";

function extractContent(s) {
  var span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
}

const PropertyCardItem = ({ data }) => {
  const navigate = useNavigate();
  let excerpt = extractContent(data.excerpt.rendered);
  excerpt = excerpt.length > 140 ? excerpt.substring(0, 140) + "..." : excerpt;

  const locale_name = data?.acf?.short_locale_name
    ? data.acf.short_locale_name.split(" ").slice(0, 3).join(" ")
    : "";
  const gallery = data.acf.gallery ? data.acf.gallery : [];

  return (
    <div className="property-card-item flex flex-col shadow-lg rounded-[4px] px-4 py-4">
      <ImageSlider gallery={gallery} slug={data.slug} />
      <Link to={"../properties/" + data.slug}>
        <div className="flex justify-between mt-2 header-font">
          <h4
            className="text-[14px] w-[60%]"
            dangerouslySetInnerHTML={{ __html: data.title }}
          ></h4>
          <SimpleIconBox text={locale_name} />
        </div>
        <p className="text-[12px] mt-3 font-light mr-8 mb-3 line-clamp-2">
          {excerpt}
        </p>
      </Link>
      <div className="mt-3 flex justify-between items-center">
        <span className="text-[12px] font-semibold text-secondary">
          {nairaFormart(data.acf.price)}{" "}
          <span className="ml-1 font-light word">
            / {data?.acf?.per_duration || "year"}
          </span>
        </span>
        <div className="flex flex-row space-x-3">
          <button
            className="rounded-md md:rounded-lg text-center text-white text-sm md:text-[12px] py-2 px-8 md:py-[6px] bg-primary hover:bg-blue-900"
            onClick={() => navigate("../properties/" + data.slug)}
          >
            View
          </button>
          <button
            className="rounded-md md:rounded-lg text-center text-white text-sm md:text-[12px] py-2 px-4 md:py-[6px] bg-primary hover:bg-blue-900 svg-icon-white"
            onClick={() =>
              navigate("/dashboard/messages", {
                state: { hash: data.author.hash },
              })
            }
          >
            <ChatSvg className="text-white" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PropertyCardItem;
