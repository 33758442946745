import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import {
  profilemenuItemList,
  accountMenuItemList,
} from "../../utils/defined-constants";
import ImageDropdown from "../dropdowns/image-dropdown/image-dropdown.component";
import AlignedImageBox from "../image-boxes/aligned-image-box/aligned-image-box";
import ProfileDropdownMenu from "../menus/profile-dropdown-menu/profile-dropdown-menu.component";
import Sidebar from "../sidebar/sidebar.component";

import notificationIcon from "../../assets/vectors/bell.svg";
import image from "../../assets/images/user.png";
import { ReactComponent as CheckedSVG } from "../../assets/vectors/Groupchecked.svg";
import MobileProfileMenu from "../menus/mobile-menu/mobile-profile-menu.component";
import { MdArrowBackIos } from "react-icons/md";

import { ReactComponent as EditUserSvg } from "../../assets/vectors/user-edit.svg";
import { ReactComponent as VerifySvg } from "../../assets/vectors/shield-tick.svg";
import { ReactComponent as SwitchRoleSvg } from "../../assets/vectors/Vector-1.svg";
import { ReactComponent as LogoutSvg } from "../../assets/vectors/logout.svg";

const DynamicMobileHeader = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isIndexHeader =
    (location.pathname === "/dashboard/property-list" ||
      location.pathname === "/dashboard/post-requests" ||
      location.pathname === "/dashboard/post-requests/") &&
    searchParams.get("action") !== "view";
  const isAccountHeader = location.pathname === "/dashboard/my-account";
  const isInnerAccountHeader = location.pathname === "/dashboard/my-account/";
  const isbasicHeader =
    (location.pathname === "/dashboard/post-requests/" &&
      searchParams.get("action") === "view") ||
    (!isInnerAccountHeader && !isIndexHeader && !isAccountHeader);

  const { user, logoutUser, mainMenuList, pageTitle } = props;

  const profilemenuItemList = [
    {
      title: "Edit Profile",
      icon: <EditUserSvg className="inline-block mr-2 h-4" />,
      path: "/my-account",
    },
    {
      title: "Verify Account",
      icon: <VerifySvg className="inline-block -ml-1 mr-1 h-4" />,
      path: "/my-account/?action=verify_account",
    },
    {
      title: "Switch Account",
      icon: <SwitchRoleSvg className="inline-block mr-2 pr-[1px] h-4" />,
      path: "/dashboard",
    },
    {
      title: "Logout",
      icon: <LogoutSvg className="inline-block mr-2 h-4" />,
      action: () => logoutUser("/login"),
    },
  ];

  return (
    <>
      {isIndexHeader && (
        <div className="sticky top-0 z-[999] bg-white w-full px-4 flex justify-between items-center pt-4 -pb-1 lg:hidden">
          <div className="flex gap-2 items-center">
            <ImageDropdown
              panelElement={
                <>
                  <AlignedImageBox
                    profileImage={user.profile_photo}
                    text={user.first_name + " " + user.last_name}
                    subtext={"@" + user.nickname}
                  />
                  <ProfileDropdownMenu menuItemList={profilemenuItemList} />
                </>
              }
              check={user.verify_user === 0 || !user.verify_user ? false : true}
              image={{ src: user.profile_photo ? user.profile_photo : "" }}
              className="rounded-full w-[40px] h-[40px]"
              appendClass={"-translate-x-[8%] -mt-[2px]"}
            />
            <span className="h-[5px] w-[5px] bg-red-600 inline-block rounded-full absolute left-14 -mt-9 -ml-2"></span>
            <span className="text-light header-font -mt-2 text-sm">
              Hi {user.first_name},
            </span>
          </div>

          <div className="flex gap-5 items-center">
            <ImageDropdown
              panelElement={<div className="p-1">lol</div>}
              image={{ src: notificationIcon, size: "24" }}
              nocaretIcon={true}
              appendClass={"-translate-x-[48%] w-[180px]"}
            />
            <Sidebar menuItemList={mainMenuList} />
          </div>
        </div>
      )}

      {isAccountHeader && (
        <div className="lg:hidden">
          <div className="sticky top-0 z-[999] bg-primary text-white w-full px-4 pt-8  flex flex-col justify-center items-center header-font">
            <div className="justify-center items-center pt-4 pb-3 text-sm">
              <Sidebar menuItemList={mainMenuList} backButton={true} />
              Account
            </div>

            <div className="mt-10 mb-5 flex flex-col justify-center items-center">
              <img
                id="profile-image-preview"
                className="p-1 shadow-md border-white border-2 cursor-pointer h-[100px] w-[100px] rounded-full object-cover"
                src={user?.profile_photo ? user.profile_photo : image}
                alt="profile-img"
              />

              <div className="flex justify-center mt-5 gap-2">
                {user.first_name + " " + user.last_name}
                {user.verify_user === 1 && <CheckedSVG className="w-5 h-5" />}
              </div>
            </div>
          </div>

          <MobileProfileMenu menuItemList={accountMenuItemList} />
        </div>
      )}

      {isInnerAccountHeader && (
        <div className="sticky top-0 z-[999] bg-white w-full px-4  flex justify-center items-center pt-4 pb-3 lg:hidden header-font text-lg">
          <MdArrowBackIos
            className="absolute left-5 z-10 w-10 h-5 inline-block cursor-pointer"
            onClick={() => navigate(-1)}
          />
          {pageTitle}
        </div>
      )}

      {isbasicHeader && (
        <div className="sticky top-0 z-[999] bg-white w-full px-4 flex justify-center items-center pt-4 pb-3 lg:hidden header-font text-lg">
          <Sidebar menuItemList={mainMenuList} backButton={true} />
          {pageTitle}
        </div>
      )}
    </>
  );
};

export default DynamicMobileHeader;
