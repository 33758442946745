/* eslint-disable react/jsx-no-undef */
import GenericModal from "../../../components/modals/generic-modal.component";
import RegularButton from "../../../components/buttons/regular-button/regular-button.component";
import VerifyUserForm from "../../../components/forms/verify-user/verify-user-form.component";

import { ReactComponent as ScanSVG } from "../../../assets/vectors/finger-scan.svg";
import { ReactComponent as InfoCircleSVG } from "../../../assets/vectors/info-circle.svg";
import { ReactComponent as CheckedSVG } from "../../../assets/vectors/Groupchecked.svg";
import { BsCamera } from "react-icons/bs";

import Lottie from "lottie-react";
import loader from "../../../assets/lottie/7774-loading.json";

import { useContext, useState, useRef, useCallback, useEffect } from "react";
import Webcam from "react-webcam";

import { UserContext } from "../../../contexts/user.context";
import { DataURIToBlob } from "../../../utils/functions";
import InputField from "../../../components/fields/input-field/form-input.component";
import * as cdigit from "cdigit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { updateWpUser } from "../../../api/wordpress";
import { useNavigate } from "react-router-dom";

const videoConstraints = {
  width: 800,
  height: 800,
  aspectRatio: 0.6666666667,
  facingMode: "user",
};

const WebcamCapture = ({ setWebCamUploadSuccessDialog, setImgBase64 }) => {
  const { accessToken, user, persistUpdatedUser } = useContext(UserContext);
  const [webcamLoader, setWebcamLoader] = useState(false);

  const webcamRef = useRef(null);
  const capture = useCallback(async () => {
    setWebcamLoader(true);
    const imgBase64 = webcamRef.current.getScreenshot();
    const file = DataURIToBlob(imgBase64);
    const formData = new FormData();
    formData.append("file", file, user.first_name + user.last_name + ".jpg");
    formData.append("file_key", "facial_capture"); //other param

    try {
      setImgBase64(imgBase64);
      // const response = await updateWpUser(accessToken, formData);
      // persistUpdatedUser(response.data);
      setWebCamUploadSuccessDialog(true);
    } catch (error) {
      console.log(error);
    }
    setWebcamLoader(false);
  }, [
    webcamRef,
    user,
    accessToken,
    persistUpdatedUser,
    setWebCamUploadSuccessDialog,
  ]);
  return (
    <div className="flex flex-col justify-center items-center gap-10 my-5">
      {webcamLoader && (
        <>
          {
            <Lottie
              width={"50px"}
              height="50px"
              animationData={loader}
              loop={true}
            />
          }
        </>
      )}
      <Webcam
        audio={false}
        width={800}
        height={800}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        className="rounded-full bg-gray-300 h-[223px] w-[223px]"
      />
      <button
        className="py-2 px-4 rounded-sm flex text-center items-center gap-2 text-white bg-primary"
        onClick={capture}
      >
        {" "}
        <BsCamera /> Capture photo
      </button>
    </div>
  );
};

const VerifyIdentityScreen = () => {
  const { user, accessToken, persistUpdatedUser } = useContext(UserContext);
  const { facial_capture } = user;
  const [uploadPath, setUploadPath] = useState("");
  const [webcamState, setWebcamState] = useState(false);
  const [webcamUploadSuccessDialog, setWebCamUploadSuccessDialog] =
    useState(false);
  const [imgBase64, setImgBase64] = useState("");
  const [loading, setLoading] = useState(false);
  const [vninerror, setVninerror] = useState("");
  const [bankCodeError, setBankCodeError] = useState("");
  const [acctNoError, setAcctNoError] = useState("");
  const [formChanged, setFormChanged] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [openVninInfo, setOpenVninInfo] = useState(true);
  const [submitErrorMsg, setSubmitErrorMsg] = useState("");
  const [bvnMode, setBvnMode] = useState(false);
  const [bankCodeOptions, setBankCodeOptions] = useState([]);
  const [vninData, setVninData] = useState();

  const navigate = useNavigate();

  const defaultFormFields = {
    vnin: "",
    bankCode: "",
    acctNo: "",
  };

  const [formFields, setFormFields] = useState(defaultFormFields);
  const { vnin, bankCode, acctNo } = formFields;

  const handleChange = (event) => {
    setFormChanged(true);
    let { name, value } = event.target;
    if (name === "vnin") value = value.toUpperCase();
    setFormFields({ ...formFields, [name]: value });
  };

  useEffect(() => {
    fetchNubanBanks();
  }, []);

  const getToken = async () => {
    const response = await axios.post("https://api.qoreid.com/token", {
      clientId: "5RBTZGWB3EC3O32ILFON",
      secret: "2d70258369ca435aa2775366b350d2b7",
    });
    const accessToken = response.data.accessToken;
    return accessToken;
  };

  const fetchNubanBanks = async () => {
    const token = await getToken();
    const banksResponse = await axios.get(
      "https://api.qoreid.com/v1/banks/bvn-nuban",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setBankCodeOptions(banksResponse.data);
  };

  const submitBVN = useCallback(async () => {
    setLoading(true);
    setSubmitDisabled(true);
    const token = await getToken();
    let bvnResponse;
    try {
      bvnResponse = await axios.post(
        "https://api.qoreid.com/v1/ng/identities/bvn-nuban",
        {
          firstname: user.first_name,
          lastname: user.last_name,
          accountNumber: acctNo,
          bankCode: bankCode,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      setSubmitErrorMsg(
        error.response.data.message.replace(/nuban/gi, "Account Number")
      );
    }

    if (bvnResponse.data.status.status === "id_mismatch") {
      setSubmitErrorMsg(
        "Identity Verification Failed: We cannot confirm that this account number belongs to you. Please ensure that your firstname and lastname is in sync with the account number provided."
      );
    } else if (
      vninData.face_verification.firstname !==
        bvnResponse.data.bvn_nuban.firstname ||
      vninData.face_verification.lastname !==
        bvnResponse.data.bvn_nuban.lastname
    ) {
      setSubmitErrorMsg(
        "Identity Verification Failed: We've noticed a discrepancy between the name on your VNIN and your account number. Please ensure that the name on both identities is synced."
      );
    }

    const bvnData = bvnResponse.data;
    if (!submitErrorMsg) {
      const formData = new FormData();
      formData.append("verify_user", 1);
      const response = await updateWpUser(accessToken, formData);
      persistUpdatedUser(response.data);
      navigate("/dashboard/my-account");
      // send bvn data to backend for logging purposes
    } else {
      // send bvn data to backend for logging purposes
    }
    setLoading(false);
  }, [user, acctNo, bankCode, vninData, submitErrorMsg]);

  const submitVNIN = async () => {
    setLoading(true);
    setSubmitDisabled(true);
    const token = await getToken();
    let vninResponse;
    try {
      vninResponse = await axios.post(
        "https://api.qoreid.com/v1/ng/identities/face-verification/vnin",
        {
          idNumber: vnin,
          photoBase64: imgBase64,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      setSubmitErrorMsg(error.response.data.message);
    }
    console.log(vninResponse);
    if (!vninResponse.data.summary.face_verification_check.match) {
      setSubmitErrorMsg(
        "Identity Verification Failed: Image provided does not match image of the identity provided"
      );
    } else {
      setBvnMode(true);
    }
    // send vnin response to backend for logging purposes
    setVninData(vninResponse.data);
    setLoading(false);
  };

  useEffect(() => {
    if (formChanged) {
      if (!bvnMode) {
        if (!formFields["vnin"]) {
          setVninerror("Please provide a VNIN");
        } else if (formFields["vnin"].length !== 16) {
          setVninerror("VNIN should not be more or less than 16 characters");
        } else if (!cdigit.mod1271_36.validate(formFields["vnin"])) {
          setVninerror("Please provide a valid VNIN");
        } else {
          setVninerror("");
        }
      } else {
        if (!formFields["bankCode"]) {
          setBankCodeError("Please select a bank");
        } else {
          setBankCodeError("");
        }

        if (!formFields["acctNo"]) {
          setAcctNoError("Please provide a account number");
        } else if (!/^[0-9]*$/.test(formFields["acctNo"])) {
          setAcctNoError("Only digits are allowed");
        } else if (formFields["acctNo"].length < 10) {
          setAcctNoError("Provided account number is too short");
        } else if (formFields["acctNo"].length > 11) {
          setAcctNoError("Provided account number is too long");
        } else {
          setAcctNoError("");
        }
      }
    }

    if (bvnMode && formChanged && !bankCodeError && !acctNoError) {
      setSubmitDisabled(false);
    } else if (!bvnMode && formChanged && !vninerror && imgBase64) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [
    formFields,
    imgBase64,
    vninerror,
    bankCodeError,
    acctNoError,
    bvnMode,
    formChanged,
  ]);

  useEffect(() => {
    setWebcamState(false);
    setUploadPath("");
  }, [webcamUploadSuccessDialog]);

  return (
    <>
      <div className="">
        <h4 className=" header-font text-[18px]">Verify Identity</h4>
      </div>
      {!user.verify_user ? (
        <div
          style={{
            width: "100%",
            background: "rgb(0 54 128 / 1)",
            color: "white",
            marginTop: "2rem",
            padding: "1rem",
          }}
        >
          Your identity has been verified
        </div>
      ) : (
        <>
          {uploadPath === "id_document" && (
            <VerifyUserForm resetPage={setUploadPath} />
          )}
          <GenericModal
            onClick={(event) => {
              if (event.target.classList.contains("close-modal")) {
                setWebcamState(false);
                setUploadPath("");
              }
            }}
            modalState={uploadPath === "facial_capture"}
            children={
              <>
                <div className="p-[25px] justify-center items-center text-center">
                  <button
                    onClick={() => {
                      setWebcamState(false);
                      setUploadPath("");
                    }}
                    className={
                      "close-modal py-[5px] px-3 rounded-full border border-black absolute top-3 right-3"
                    }
                  >
                    X
                  </button>
                  <span className="text-lg font-semibold block">
                    Take Selfie
                  </span>

                  {webcamState === false ? (
                    <>
                      <div className="rounded-full p-2 border border-secondary inline-block my-8">
                        <div className="flex justify-center items-center rounded-full bg-gray-300 h-[223px] w-[223px]">
                          <ScanSVG className="w-[136px] h-[136px] m-10" />
                        </div>
                      </div>
                      <div className="text-left flex flex-col gap-2 mb-6">
                        <h2 className="text-md font-normal">Good Lighting</h2>
                        <p className="text-sm font-light text-gray-500">
                          Make sure you are in a well lit area Make sure you are
                          in a well lit area Make sure you are in a well lit
                          area{" "}
                        </p>
                      </div>
                      <div className="text-left flex flex-col gap-2 mb-8">
                        <h2 className="text-md font-normal">Look Straight</h2>
                        <p className="text-sm font-light text-gray-500">
                          Make sure you are in a well lit area Make sure you are
                          in a well lit area Make sure you are in a well lit
                          area{" "}
                        </p>
                      </div>
                      <RegularButton
                        linkTo={"/dashboard/my-account/?action=verify_account"}
                        onClick={() => setWebcamState(true)}
                        className={"text-center"}
                        text={
                          <div className="w-full text-center py-2 text-md text-light ">
                            Open Camera
                          </div>
                        }
                      />
                    </>
                  ) : (
                    <WebcamCapture
                      setWebCamUploadSuccessDialog={
                        setWebCamUploadSuccessDialog
                      }
                      setImgBase64={setImgBase64}
                    />
                  )}
                </div>
              </>
            }
          />
          <GenericModal
            onClick={(event) => {
              if (event.target.classList.contains("close-modal")) {
                setOpenVninInfo(false);
              }
            }}
            children={
              <div
                style={{
                  padding: "1rem 2rem",
                }}
              >
                <button
                  className={
                    "close-modal py-[5px] px-3 rounded-full border border-black absolute top-3 right-3"
                  }
                >
                  X
                </button>
                <p style={{ fontSize: "2rem" }}>Generating a vNIN</p>
                <p style={{ marginTop: ".5rem", fontSize: "1.1rem" }}>
                  There are two ways to generate a virtual NIN:
                </p>
                <ol style={{ marginTop: "1rem" }}>
                  <li style={{ listStyle: "number" }}>
                    <span style={{ fontWeight: "bold" }}>Via USSD</span>
                    <p style={{ marginTop: ".5rem" }}>
                      To generate a Virtual NIN via USSD, dial{" "}
                      <span style={{ fontWeight: "bold" }}>
                        *346*3*Your NIN*715461#
                      </span>
                      . An SMS message will be sent back to you containing the
                      Virtual NIN generated for you.
                    </p>
                  </li>
                  <li style={{ listStyle: "number", marginTop: "1rem" }}>
                    <span style={{ fontWeight: "bold" }}>
                      Through the NIMC app
                    </span>
                    <ol style={{ marginLeft: "1rem", marginTop: ".5rem" }}>
                      <li style={{ listStyle: "lower-roman" }}>
                        Download the NIMC App - Click on "Get Virtual NIN"
                      </li>
                      <li
                        style={{ listStyle: "lower-roman", marginTop: ".3rem" }}
                      >
                        Select "Input Enterprise short-code" and type 715461
                      </li>
                      <li
                        style={{ listStyle: "lower-roman", marginTop: ".3rem" }}
                      >
                        Click "Submit" and your virtual NIN will be generated.
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            }
            modalState={openVninInfo}
          />
          <GenericModal
            onClick={(event) => {
              if (event.target.classList.contains("close-modal")) {
                setWebCamUploadSuccessDialog(false);
              }
            }}
            children={
              <div className="m-10 flex flex-col gap-5 text-center">
                <video loop autoPlay>
                  <source
                    width={"140px"}
                    height={"140px"}
                    src={require("../../../assets/videos/104369-check-motion.mp4")}
                    type="video/mp4"
                  />
                </video>
                Your facial photo has been captured for processing !
                <button
                  onClick={() => {
                    setWebCamUploadSuccessDialog(false);
                  }}
                  className=" close-modal w-full rounded-md text-white p-3 bg-primary hover:bg-blue-900 font-semibold cursor-pointer"
                >
                  Ok
                </button>
              </div>
            }
            modalState={webcamUploadSuccessDialog}
          />
          {uploadPath !== "id_document" && bvnMode ? (
            <>
              <div className="mt-8" style={{ position: "relative" }}>
                <span
                  className="text-sm text-gray-500 "
                  style={{ marginBottom: ".5rem", display: "inline-block" }}
                >
                  Step 3/4
                </span>
                <InputField
                  type="select"
                  name="bankCode"
                  value={bankCode}
                  required
                  error={bankCodeError}
                  onChange={handleChange}
                  options={bankCodeOptions}
                  style={{ appearance: "none" }}
                />
              </div>
              <div className="mt-8" style={{ position: "relative" }}>
                <span
                  className="text-sm text-gray-500 "
                  style={{ marginBottom: ".5rem", display: "inline-block" }}
                >
                  Step 4/4
                </span>
                <InputField
                  placeholder="Input Account Number"
                  type="text"
                  name="acctNo"
                  value={acctNo}
                  required
                  error={acctNoError}
                  onChange={handleChange}
                />
              </div>
              <div className="field_wrapper mb-5">
                <button
                  disabled={submitDisabled}
                  className="w-full rounded-md text-white p-3 bg-primary hover:bg-blue-900 font-semibold cursor-pointer"
                  onClick={submitBVN}
                >
                  {loading && (
                    <svg
                      role="status"
                      className="inline w-4 h-4 mr-3 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                  Submit
                </button>
              </div>
              <span style={{ color: "red" }}>{submitErrorMsg}</span>
            </>
          ) : (
            <>
              {imgBase64 ? (
                <img
                  src={imgBase64}
                  alt="Facial"
                  style={{ marginTop: "1.25rem" }}
                />
              ) : (
                <div className="mt-5">
                  <span className="text-sm text-gray-500 ">Step 1/4</span>
                  <button
                    onClick={() => {
                      setUploadPath("facial_capture");
                    }}
                    className="flex justify-between border border-gray-400 hover:bg-gray-50 hover:shadow-sm py-6 px-6 w-full mt-2 mb-4 rounded-md"
                  >
                    <div className="flex gap-2 items-center text-[12px]">
                      <ScanSVG />
                      <span className="font-light text-[16px]">
                        Take a headshot
                      </span>
                    </div>
                    {facial_capture === false ? (
                      <InfoCircleSVG />
                    ) : (
                      <CheckedSVG />
                    )}
                  </button>
                </div>
              )}
              <div className="mt-8" style={{ position: "relative" }}>
                <span
                  className="text-sm text-gray-500 "
                  style={{ marginBottom: ".5rem", display: "inline-block" }}
                >
                  Step 2/4
                </span>
                <span
                  style={{
                    position: "absolute",
                    right: 0,
                    top: "0.2rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpenVninInfo(true);
                  }}
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
                <InputField
                  placeholder="Input VNIN"
                  type="text"
                  name="vnin"
                  value={vnin}
                  required
                  error={vninerror}
                  onChange={handleChange}
                />
              </div>
              <div className="field_wrapper mb-5">
                <button
                  disabled={submitDisabled}
                  className="w-full rounded-md text-white p-3 bg-primary hover:bg-blue-900 font-semibold cursor-pointer"
                  onClick={submitVNIN}
                >
                  {loading && (
                    <svg
                      role="status"
                      className="inline w-4 h-4 mr-3 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                  Next
                </button>
              </div>
              <span style={{ color: "red" }}>{submitErrorMsg}</span>
            </>
          )}
        </>
      )}
    </>
  );
};

export default VerifyIdentityScreen;
