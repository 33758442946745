import { useState, useEffect } from "react";
import { usePaystackPayment } from "react-paystack";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getSingleProperty, runMultiBatchRequest } from "../../api/wordpress";

import BgModal from "../../components/modals/background-modal.component";
import GenericModal from "../../components/modals/generic-modal.component";

import logoRepeat from "../../assets/vectors/logo-repeat.svg";
import { ReactComponent as VerifySVG } from "../../assets/vectors/verify.svg";
import { ReactComponent as ErrorSVG } from "../../assets/vectors/error.svg";
import { CryptoJSAesJson, nairaFormart } from "../../utils/functions";
import axios from "axios";
import { ReactComponent as MarkerSvg } from "../../assets/vectors/place_black_24dp-1.svg";

const config = {
  reference: "",
  email: "",
  amount: 0,
  publicKey: "pk_test_4aa3ebf838db8ad47944d0832d319b3ee99a3113",
};

const CheckOutScreen = () => {
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const [parsedConfig, setParsedConfig] = useState(config);
  const [loader, setLoader] = useState(true);
  const [dialogData, setDialogData] = useState({});
  const [dialogState, setDialogState] = useState(false);
  const [checkoutProperty, setCheckoutProperty] = useState({});
  const token = JSON.parse(localStorage.getItem("user"))?.token;
  const email = JSON.parse(localStorage.getItem("user"))?.user?.user_email;

  var CryptoJS = require("crypto-js");

  // you can call this function anything
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
    if (reference?.status === "success") {
      setLoader(true);
      const reciever_email = CryptoJS.AES.decrypt(
        checkoutProperty?.author?.hash,
        process.env.REACT_APP_HASH_KEY,
        { format: CryptoJSAesJson }
      )
        .toString(CryptoJS.enc.Utf8)
        .replaceAll('"', "");
      const propertyFormData = new FormData();
      const transactionFormData = new FormData();

      propertyFormData.append("id", checkoutProperty.id);
      propertyFormData.append("author_id", checkoutProperty?.author?.ID);
      propertyFormData.append("occupancy_status", true);

      transactionFormData.append("paying_customer", email);
      transactionFormData.append("recieving_customer", reciever_email);
      transactionFormData.append(
        "paystack_reference_key",
        reference?.reference
      );
      transactionFormData.append("user_email", email);
      transactionFormData.append("property", checkoutProperty.id);

      var buildRequestObjList = [
        {
          path: "/update-property",
          method: "post",
          data: propertyFormData,
        },
        {
          path: "/create-transaction",
          method: "post",
          data: transactionFormData,
        },
      ];

      const propertyUpdate = async () => {
        await runMultiBatchRequest(token, buildRequestObjList).then(
          axios.spread((...responses) => {
            responses.forEach((response) => {
              console.log(response);
            });
          })
        );
        navigate("/dashboard/payment-history");
      };

      propertyUpdate().catch((e) => {
        console.log(e);
      });
    }
    setDialogData({
      title: "",
      subtitle: "",
      svgComp: <VerifySVG />,
    });
    if (false) {
      setDialogData({
        title: "",
        subtitle: "",
        svgComp: <ErrorSVG />,
      });
    }
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  useEffect(() => {
    document.title = "Checkout";
    const propertyID = queryParams.get("paymentID");

    const propertyRequest = async () => {
      const response = await getSingleProperty(token, {
        include: [propertyID],
      });
      const { data } = response;
      setCheckoutProperty(data);
      const reciever_email = CryptoJS.AES.decrypt(
        data?.author?.hash,
        process.env.REACT_APP_HASH_KEY,
        { format: CryptoJSAesJson }
      )
        .toString(CryptoJS.enc.Utf8)
        .replaceAll('"', "");
      setParsedConfig({
        ...parsedConfig,
        reference:
          data?.id?.toString() + new Date(data?.date).getTime().toString(),
        amount: data?.acf?.price * 100,
        email: email,
        label: data?.title,
        metadata: {
          id: queryParams.get("paymentID"),
          reciever_email: reciever_email,
          address: data?.acf?.address,
          thumbnail_url: data?.acf?.gallery[0]?.full_image_url,
        },
      });
      setLoader(false);
    };

    propertyRequest().catch((error) => {
      console.log(error);
    });
  }, []);

  const initializePayment = usePaystackPayment(parsedConfig);
  return (
    <>
      {loader ? (
        <BgModal modalState={true} />
      ) : (
        <div
          className="min-h-screen w-screen bg-primary flex justify-center items-center checkout-page"
          style={{ backgroundImage: `url(${logoRepeat})` }}
        >
          <div className="bg-white rounded-lg shadow-lg min-w-[500px] flex flex-col gap-5 items-start p-5">
            <img
              className="rounded-sm w-[100%] max-h-[300px] object-cover"
              src={checkoutProperty?.acf?.gallery[0]?.full_image_url}
              height="150px"
              width="200px"
              alt={checkoutProperty?.title}
            />
            <div className="w-full flex flex-row justify-between">
              <div className="flex flex-col">
                <p className="text-secondary text-[10px]">Price:</p>
                <h4 className="text-[16px] font-semibold text-primary">
                  {nairaFormart(checkoutProperty?.acf?.price)}
                </h4>
              </div>
              <div className="relative w-4/6 min-h-[41px] border border-[#959595] border-opacity-50 rounded-md px-4 py-2 address">
                <div className="-left-[10px] top-0 bottom-0 absolute w-[21px] h-[21px] rounded-full bg-primary text-white flex items-center justify-center">
                  <MarkerSvg />
                </div>
                <p className="text-[12px] font-normal text-primary">
                  {checkoutProperty?.acf?.address &&
                  checkoutProperty?.acf?.address !== ""
                    ? checkoutProperty?.acf?.address
                    : "No Address"}
                </p>
              </div>
            </div>
            <div className="flex flex-row items-center space-x-3 text-[#959595] text-[14px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                />
              </svg>

              <p className="">
                Make sure you’re paying for the right apartment
              </p>
            </div>
            <div className=" flex flex-row w-full space-x-10">
              <button
                className="bg-primary w-1/2 flex text-white flex-row items-center justify-center border py-2 px-8 rounded-md hover:bg-gray-100"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <span>No</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <button
                className="bg-white w-1/2 flex flex-row items-center justify-center py-2 px-8 text-center rounded-md border border-[#656565] text-[#656565]"
                onClick={() => {
                  initializePayment(onSuccess, onClose);
                }}
              >
                <span>Yes</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
              </button>
            </div>
          </div>
          <GenericModal
            onClick={(event) => {
              if (event.target.classList.contains("close-modal")) {
                setDialogState(false);
              }
            }}
            modalState={dialogState}
            children={
              <div className="p-[54px] justify-center items-center text-center">
                <button
                  onClick={() => {
                    setDialogState(false);
                  }}
                  className={
                    " close-modal py-[5px] px-3 rounded-full border border-black absolute top-3 right-3"
                  }
                >
                  X
                </button>
                <div className="flex flex-col gap-5 justify-center items-center">
                  {dialogData?.svgComp}
                  {dialogData?.title && (
                    <h3 className="text-bold text-[20px] header-font">
                      {dialogData?.title}
                    </h3>
                  )}
                  {dialogData?.subtitle && (
                    <p className="font-light text-strong-[200] text-md mb-4">
                      {dialogData?.subtitle}
                    </p>
                  )}
                </div>
                {false && (
                  <div className="justify-center px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="close-modal w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="close-modal handle-delete mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Confirm
                    </button>
                  </div>
                )}
              </div>
            }
          />
        </div>
      )}
    </>
  );
};

export default CheckOutScreen;
