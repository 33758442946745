import AlignedImageBox2 from "../../../components/image-boxes/aligned-image-box/aligned-image-box-2";
import { nairaFormart } from "../../../utils/functions";

const PropertyHistoryList = ({ data }) => {
  console.log(data);
  return (
    <div className="w-full divide-y divider-black">
      <div className="table-header flex font-semibold header-font text-[14px] gap-8 pb-2">
        <div className="w-[25%]">Property</div>
        <div className="w-[15%]">Address</div>
        <div className="w-[15%]">Payment method</div>
        <div className="w-[15%]">Amount paid</div>
        <div className="w-[15%] text-center">Status</div>
        <div className="w-[10%]"></div>
      </div>
      {data.map((item, index) => {
        return (
          <div
            key={index}
            className="table-header flex font-light gap-5 text-[14px] w-full py-4"
          >
            <div className="w-[25%]">
              <AlignedImageBox2
                profileImage={item.acf.property.gallery[0].full_image_url}
                text={item.acf.property.core.post_title}
                subtext={" "}
              />
            </div>
            <div className="w-[15%]">{item.acf.property.address}</div>
            <div className="w-[15%]">Transfer</div>
            <div className="w-[15%]">
              {nairaFormart(item.acf.property.price)}
            </div>
            <div className="w-[15%] text-center">
              <span className="text-green bg-green-200 py-1 px-4 rounded-2xl text-sm">
                Successful
              </span>
            </div>
            <div className="w-[10%]"></div>
          </div>
        );
      })}
    </div>
  );
};

export default PropertyHistoryList;
