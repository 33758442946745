import { useState } from "react";
import CurrencyFormat from "react-currency-format";

export const CurrencyInputField = ({ label, ...otherProps }) => {
  return (
    <>
      <div className="mt-10 mb-10 relative w-full">
        <CurrencyFormat
          value={otherProps.value}
          thousandSeparator={true}
          prefix={"₦"}
          required={otherProps.required ?? false}
          className={
            "w-full rounded-md p-4 border focus:ring-0 focus:outline-none border-gray-300 " +
            ({ ...otherProps }?.disabled
              ? "disabled:opacity-75 cursor-not-allowed bg-stone-200"
              : "")
          }
          style={{ outlineWidth: ".5px" }}
          onValueChange={(values) => {
            const { formattedValue, value } = values;
            if (value > 0) {
              otherProps.onChange(value);
            }
          }}
        />
        {label && (
          <label
            htmlFor={otherProps.name}
            className="absolute text-sm text-gray-600 pb-3 -top-7 duration-300 origin-0 left-0"
          >
            {" "}
            {label}{" "}
          </label>
        )}
      </div>
    </>
  );
};

export default CurrencyInputField;
