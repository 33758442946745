export const InputField = ({ label, ...otherProps }) => {
  const renderTextarea = otherProps.type === "textarea";
  const renderSelect = otherProps.type === "select";
  if (renderTextarea || renderSelect) delete otherProps.type;

  return (
    <>
      <div className="field_wrapper mb-10">
        {label && (
          <label
            htmlFor={otherProps.name}
            className="block text-sm text-gray-600 pb-3"
          >
            {" "}
            {label}{" "}
          </label>
        )}
        {!renderTextarea ? (
          renderSelect ? (
            <>
              <select
                {...otherProps}
                className={
                  "w-full rounded-md p-4 border focus:ring-0 focus:outline-none border-gray-300 " +
                  ({ ...otherProps }?.disabled
                    ? "disabled:opacity-75 cursor-not-allowed bg-stone-200"
                    : "")
                }
              >
                <option disabled value="">Choose one</option>
                {otherProps.options.map((o) => {
                  return <option value={o.code}>{o.name}</option>;
                })}
              </select>
              {otherProps.error && (
                <span style={{ color: "red" }}>{otherProps.error}</span>
              )}
            </>
          ) : (
            <>
              <input
                {...otherProps}
                className={
                  "w-full rounded-md p-4 border focus:ring-0 focus:outline-none border-gray-300 " +
                  ({ ...otherProps }?.disabled
                    ? "disabled:opacity-75 cursor-not-allowed bg-stone-200"
                    : "")
                }
                style={{ outlineWidth: ".5px" }}
              />
              {otherProps.error && (
                <span style={{ color: "red" }}>{otherProps.error}</span>
              )}
            </>
          )
        ) : (
          <textarea
            rows={4}
            {...otherProps}
            className="w-full rounded-md border-solid border border-gray-300 p-4 focus:ring-0 focus:outline-none text-sm font-extralight text-stone-500"
          ></textarea>
        )}
      </div>
    </>
  );
};

export default InputField;
