import axios from "axios";
import { urlFromObject } from "../../utils/functions";

const baseUrl = process.env.REACT_APP_API_HOST_URL + "/wp-json";
const apiUrl = baseUrl + "/rummies-wp/v1";
const authUrl = apiUrl + "/authenticate";

//
/**
 *
 * @param {string} username
 * @param {string} password
 * @returns
 */
export const authenticateWpUser = async (data) => {
  let response = await axios.post(authUrl, data);

  return response;
};

//
/**
 *
 * @param {string} email
 * @returns
 */
export const resetWpUserPassword = async (data) => {
  let response = await axios({
    method: "post",
    url: `${apiUrl}/reset-password`,
    data: { ...data },
  });

  return response;
};

//
/**
 * @param {string} code
 * @param {string} email
 * @param {string} password
 * @returns
 */
export const setNewPasswordWpUser = async (email, id, code, password) => {
  let response = await axios.post(`${apiUrl}/set-password`, {
    email: email,
    id: id,
    code: code,
    password: password,
  });

  return response;
};

export const getWpUser = async (token) => {
  const appendPath = "/validate-user";

  let response = await axios.get(apiUrl + appendPath, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  return response;
};

export const updateWpUser = async (
  token,
  formData,
  uploadProgressCallback = () => {}
) => {
  const appendPath = "/update-user";

  const response = await axios({
    method: "post",
    url: apiUrl + appendPath,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
    onUploadProgress: uploadProgressCallback,
  });

  return response;
};

export const createWpUser = async ({ ...data }) => {
  const appendPath = "/create-user";
  const response = await axios({
    method: "post",
    url: apiUrl + appendPath,
    data: { ...data },
  });

  return response;
};

export const updateWpUserPassword = async (token, formData) => {
  const appendPath = "/update-password";

  const response = await axios({
    method: "post",
    url: apiUrl + appendPath,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  });

  return response;
};

export const deleteWpUser = ({ email }) => {
  axios
    .post(
      apiUrl + "/delete-user",
      {
        email: email,
      },
      {
        headers: {
          Authorization: "Basic " + process.env.BASIC_AUTH_PASSWORD,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response;
    });
};

//Property request route
/**
 *
 * @param {string} token
 * @param {json} params
 * @returns
 */
export const getPropertyList = async (token, params) => {
  let appendPath = "/property";

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  if (params) appendPath += "?" + urlFromObject(params);

  let response = await axios.get(apiUrl + appendPath, config);

  return response;
};

export const getSingleProperty = async (token, { ...params }) => {
  const data = { ...params };
  const appendPath = "/property";

  const config = {
    method: "get",
    url: apiUrl + appendPath,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    params: data,
  };

  const response = await axios(config);

  return response;
};

export const createProperty = async (token, formData) => {
  const appendPath = "/create-property";
  const response = await axios({
    method: "post",
    url: apiUrl + appendPath,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  });

  return response;
};

export const updateProperty = async (token, formData) => {
  const appendPath = "/update-property";
  const response = await axios({
    method: "post",
    url: apiUrl + appendPath,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  });

  return response;
};

//Post requests request route
/**
 *
 * @param {string} token
 * @param {json} params
 * @returns
 */
export const getPostRequestList = async (token, params) => {
  let appendPath = "/property-request";

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  if (params) appendPath += "?" + urlFromObject(params);

  let response = await axios.get(apiUrl + appendPath, config);

  return response;
};

export const getSinglePostRequest = async (token, { ...params }) => {
  const data = { ...params };
  const appendPath = "/post-request";

  const config = {
    method: "get",
    url: apiUrl + appendPath,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    params: data,
  };

  const response = await axios(config);

  return response;
};

export const prepareUpdatePostRequest = async (token, { ...params }) => {
  const appendPath1 = "/property-request";
  const appendPath2 = "/fetch-acf-field";

  const requestOne = axios.get(apiUrl + appendPath1, {
    params: {
      slug: params.slug,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
  const requestTwo = axios.get(apiUrl + appendPath2, {
    params: {
      field: params.field,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
  const responses = axios.all([requestOne, requestTwo]);

  return responses;
};

export const updatePostRequest = async (token, formData) => {
  const appendPath = "/update-property-request";
  const response = await axios({
    method: "post",
    url: apiUrl + appendPath,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  });

  return response;
};

export const createPostRequest = async (token, formData) => {
  const appendPath = "/create-property-request";
  const response = await axios({
    method: "post",
    url: apiUrl + appendPath,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  });

  return response;
};

export const createScheduleRequest = async (token, formData) => {
  const appendPath = "/create-schedule";
  const response = await axios({
    method: "post",
    url: apiUrl + appendPath,
    data: formData,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  return response;
};

export const updateScheduleRequest = async (token, formData) => {
  const appendPath = "/update-schedule";
  const response = await axios({
    method: "post",
    url: apiUrl + appendPath,
    data: formData,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  return response;
};

export const deletePostRequest = async (token, slug) => {
  const appendPath = "/delete-property-request";

  let response = await axios({
    method: "post",
    url: apiUrl + appendPath,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    params: {
      slug: slug,
    },
  });

  return response;
};

//multiple queryRequest

/**
 *
 * @param {string} token
 * @param {array} requestBuildObj
 * @returns
 */
export const runMultiBatchRequest = async (token, requestObjBuildList) => {
  const requests = [];
  requestObjBuildList.forEach(({ path, method, data }) => {
    const config = {
      method: method,
      url: apiUrl + path,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    if (method === "get") {
      if (data !== "") config["params"] = data;
    } else if (method === "post") {
      if (data !== "") config["data"] = data;
    }
    requests.push(axios(config));
  });

  const responses = await axios.all(requests);

  return responses;
};

//custom fields query
export const fetchField = async (token, field) => {
  const appendPath = "/fetch-acf-field";

  let response = await axios.get(apiUrl + appendPath, {
    params: field,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  return response;
};

