
import PropertyCardItem from "../../card-items/property-card-item/property-card-item.component";
import NotFoundRender from "../../info-panes/not-found.component";

const PropertyCardLoop = ( {propertyList}) => {
    return(
        <>
        {   
            propertyList.length > 0 ? 
                <div className="grid md:grid-cols-3 gap-5">  
                {
                    propertyList.map((item) => {
                        return( <PropertyCardItem key={item.id} data={item} />)
                    })
                }
                </div>
            :
                <NotFoundRender message="No Post was found in this search filter" icon="" state='error' />
        }
        </>
    )

}

export default PropertyCardLoop;