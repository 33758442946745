import { ReactComponent as HouseSvg } from "../assets/vectors/house.svg";
import { ReactComponent as UserSvg } from "../assets/vectors/user@2x.svg";
import { ReactComponent as EditPostSvg } from "../assets/vectors/edit.svg";
import { ReactComponent as ChatSvg } from "../assets/vectors/message-text.svg";
import { ReactComponent as HistorySvg } from "../assets/vectors/history.svg";
import { ReactComponent as BadgeSvg } from "../assets/vectors/Property-34.svg";

import { ReactComponent as EditSvg } from "../assets/vectors/user-edit-1.svg";
import { ReactComponent as KeySvg } from "../assets/vectors/key.svg";
import { ReactComponent as LocationSvg } from "../assets/vectors/location.svg";

export const menuItemList = [
  {
    title: "Properties Listing",
    icon: <HouseSvg className="inline-block mr-3" />,
    path: "/property-list",
  },
  {
    title: "Account",
    icon: <UserSvg className="inline-block mr-3" />,
    path: "/my-account",
  },
  {
    title: "My Post Requests",
    icon: <EditPostSvg className="inline-block mr-3" />,
    path: "/my-post-requests",
  },
  {
    title: "Messages",
    icon: <ChatSvg className="inline-block mr-3" />,
    path: "/messages",
  },
  {
    title: "Payment History",
    icon: <HistorySvg className="inline-block mr-3" />,
    path: "/payment-history",
  },
  {
    title: "Live Scheduling",
    icon: <LocationSvg className="inline-block mr-3" />,
    path: "/schedules",
  },
  {
    title: "Terms & Conditions",
    icon: <BadgeSvg className="inline-block mr-3" />,
    path: "/terms-conditions",
  },
];


export const accountMenuItemList = [
  {
    title: "Edit Profile",
    icon: <EditSvg className="inline-block mr-3" />,
    path: "/my-account/",
  },
  {
    title: "Verify Identity",
    icon: <BadgeSvg className="inline-block mr-3" />,
    path: "/my-account/?action=verify_account",
  },
  {
    title: "Password & Security",
    icon: <KeySvg className="inline-block mr-3" />,
    path: "/my-account/?action=password_security",
  },
];
