import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import EditProfileScreen from "./params/edit-profile.screen";
import PasswordSecurityScreen from "./params/password-security.screen";
import VerifyIdentityScreen from "./params/verify-identity.screen";

const MyaccountScreen = () => {

    const [renderComponent, setRenderComponent] = useState(null);
    const [searchParams] = useSearchParams()

    useEffect(() => {
        const action =searchParams.get('action');
        let title = '';

        switch (action){
            case null:
                title = 'Edit Profile';
                setRenderComponent(<EditProfileScreen />);
                break;
            case 'verify_account':
                title = 'Verify Identity';
                setRenderComponent(<VerifyIdentityScreen />);
                break;
            case 'password_security':
                title = 'Password & Security';
                setRenderComponent(<PasswordSecurityScreen />);
                break;
            default:
                setRenderComponent(<EditProfileScreen />)
                break;
        }
        document.title = title;
    }, [setRenderComponent, searchParams]);


    return(
        <> 
            {renderComponent}
        </>
    );
}

export default MyaccountScreen;